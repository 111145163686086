import React from "react";
import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import {CompletedTaskData} from "../../shared_with_firebase_functions/types";
import CompletedTaskModal from "./CompletedTaskModal";

const CompletedTaskComponent: React.FC<{ connectionId: string, taskData: CompletedTaskData, taskId: string }> = ({connectionId, taskData, taskId}) => {

    const submittalDate = new Date(taskData.submittal_datetime).toLocaleDateString();
    const submittalTime = new Date(taskData.submittal_datetime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    })
    const subtitleText = `Submitted on ${submittalDate} at ${submittalTime}`

    return (
        <div  key={taskId}>
            <IonCard
                color={taskData.submittal_state === "APPROVED" ? "success" : "danger"}
                id={`open-modal-${taskId}`}
                button={true}
            >
                <IonCardContent>
                    <IonCardTitle> {taskData.title} </IonCardTitle>
                    <IonCardSubtitle> {subtitleText} </IonCardSubtitle>
                </IonCardContent>
            </IonCard>
            <CompletedTaskModal
                taskData={taskData}
                taskId={taskId}
            />
        </div>
    )
}

export default CompletedTaskComponent;