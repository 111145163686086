// @ts-nocheck
import React, {useRef, useEffect} from 'react';
import {register} from 'swiper/element/bundle';
import {IonCard, IonCardContent, IonCardTitle, IonImg} from "@ionic/react";
import connectionsjpg from "./images/1connections.jpg";
import tasksjpg from "./images/2tasks.jpg";
import submissionjpg from "./images/3submission.jpg";
import approvaljpg from "./images/4approval.jpg";


register();

export const HomePageSwiper = () => {
    const swiperElRef = useRef(null);

    useEffect(() => {
        // listen for Swiper events using addEventListener
        swiperElRef.current.addEventListener('progress', (e) => {
            const [swiper, progress] = e.detail;
        });

        swiperElRef.current.addEventListener('slidechange', (e) => {
            // do on slide change

        });
    }, []);

    return (
        <div>
            <swiper-container
                ref={swiperElRef}
                slides-per-view={window.innerWidth >= 500 ? "2.2" : "1.2"}
                navigation="true"
                pagination="true"
            >
                <swiper-slide>
                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle>
                                Invite a friend
                            </IonCardTitle>
                        </IonCardContent>
                        <IonCard>
                            <IonImg src={connectionsjpg}/>
                        </IonCard>
                    </IonCard>
                </swiper-slide>
                <swiper-slide>
                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle>
                                Create tasks and rules
                            </IonCardTitle>
                        </IonCardContent>
                        <IonCard>
                            <IonImg src={tasksjpg}/>
                        </IonCard>
                    </IonCard>
                </swiper-slide>
                <swiper-slide>
                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle>
                                Enforce them with photo proof
                            </IonCardTitle>
                        </IonCardContent>
                        <IonCard>
                            <IonImg src={submissionjpg}/>
                        </IonCard>
                    </IonCard>
                </swiper-slide>
                <swiper-slide>
                    <IonCard>
                        <IonCardContent>
                            <IonCardTitle>
                                Approve, or punish
                            </IonCardTitle>
                        </IonCardContent>
                        <IonCard>
                            <IonImg src={approvaljpg}/>
                        </IonCard>
                    </IonCard>
                </swiper-slide>
            </swiper-container>
        </div>
    );
};

export default HomePageSwiper;