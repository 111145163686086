import {
    IonContent, IonPage, IonSpinner,

} from "@ionic/react";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import {useUserContext} from "../../PrePageLoading/AuthedDataLoader";
import {DateTime} from "luxon";
import {getAuth} from "firebase/auth";

const RefPage: React.FC = () => {
    const {
        urlRole,
        urlConnectionId
    } = useParams<{ urlRole: string | undefined, urlConnectionId: string | undefined }>();
    const userData = useUserContext();
    const history = useHistory()
    const redirectToConnectionsPage = () => history.push("/connections")

    useEffect(() => {
        const attemptJoinConnection = async () => {
            const isAnExistingConnection = !!(urlRole && urlConnectionId);
            if (!isAnExistingConnection) {
                redirectToConnectionsPage();
            } else {
                try {
                    const functions = getFunctions()
                    const joinToUnconnectedConnection = httpsCallable(functions, "joinToUnconnectedConnectionCallable")
                    await joinToUnconnectedConnection({
                        uid: userData.uid,
                        connectionId: urlConnectionId,
                        originatorIsMaster: !!Number(urlRole),
                        timezone: DateTime.now().zone.name
                    })
                    const auth = getAuth()
                    await auth.currentUser?.getIdToken(true)
                    redirectToConnectionsPage();
                } catch (error: any) {
                    console.error(error)
                    redirectToConnectionsPage();
                }

            }
        }
        attemptJoinConnection();
    }, [urlConnectionId, urlRole, userData.uid])

    return (
        <IonPage>
            <IonContent fullscreen>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                    Joining connection. Please wait... <IonSpinner/>
                </div>
            </IonContent>
        </IonPage>
    )

}

export default RefPage;