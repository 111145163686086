import {IonButtons, IonMenuButton} from "@ionic/react";

export const NavMenuButtonComponent: React.FC = () => {
    return (
        <IonButtons slot={"end"}>
            <IonMenuButton menu={"navMenu"}/>
        </IonButtons>
    )
}

export default NavMenuButtonComponent;