import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonSpinner} from "@ionic/react";
import React, {useState} from "react";
import RuleModal from "./RuleModal";
import newRuleInitValues from "./newRuleInitValues";
import {collection, doc, getFirestore} from "firebase/firestore";

const evaluateRuleLimitsToDetermineTitleText = (rulesLength: number, isPremiumConnection: boolean) => {
    if (isPremiumConnection) {
        return rulesLength >= 15 ? "Rule Limit Reached" : "Add New Rule";
    } else {
        return rulesLength >= 1 ? "Subscribe for additional rules" : "Add New Rule";
    }
}

const NewRuleButton: React.FC<{ rulesLength: number, isPremiumConnection: boolean }> = ({rulesLength, isPremiumConnection}) => {
    let db = getFirestore();
    const [newModalRuleId, setNewModalRuleId] = useState(doc(collection(db, "recurring_tasks")).id)
    const [isSaving, setIsSaving] = useState(false);

    const newRuleTitleText = evaluateRuleLimitsToDetermineTitleText(rulesLength, isPremiumConnection);

    return (
        <>
            <IonCard disabled={isSaving || newRuleTitleText !== "Add New Rule"} color={"dark"} id={`open-modal-${newModalRuleId}`} button={true}>
                <IonCardContent>
                    <IonCardTitle>
                        {newRuleTitleText}
                    </IonCardTitle>
                    {isSaving ?
                        <IonCardSubtitle>
                            <IonSpinner/>
                            Creating new rule...
                        </IonCardSubtitle>
                        : null}
                </IonCardContent>
            </IonCard>
            <RuleModal
                key={newModalRuleId}
                ruleId={newModalRuleId}
                ruleData={newRuleInitValues}
                resetInitValues={() => {
                    setNewModalRuleId(doc(collection(db, "recurring_tasks")).id);
                }}
                setIsSaving={setIsSaving}
                isNewRule={true}

            />
        </>
    )
}

export default NewRuleButton;