export const focusIonRefOnEnter = (ref: React.RefObject<HTMLIonInputElement>) => {
    return ((e: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (e.key === "Enter" && ref.current) {
            ref.current.setFocus();
        }
    })
}

export const focusRefOnEnter = (ref: React.RefObject<HTMLInputElement>) => {
    return ((e: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (e.key === "Enter" && ref.current) {
            ref.current.focus();
        }
    })
}

export const validateEmail = (email: string): boolean => {
    const re = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);
    return !!String(email).toLowerCase().match(re);
}