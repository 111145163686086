import {
    IonButton,
    IonCard,
    IonCardContent, IonCardSubtitle,
    IonCardTitle,
    IonContent,
    IonHeader, IonInput, IonLabel,
    IonPage, IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import {Dispatch, SetStateAction, useState} from "react";
import {useConnectionIndexContext,
    useUserContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import ConnectionDetailsComponent from "./ConnectionDetailsComponent";
import {getFunctions, httpsCallable} from "firebase/functions";
import {DateTime} from "luxon";
import {getAuth} from "firebase/auth";
import {useHistory} from "react-router-dom";

const evaluateConnectionLimit = (connectionsLength: number, isPremiumConnection: boolean) => {
    if (isPremiumConnection) {
        return connectionsLength >= 10 ? "Connection Limit Reached" : "Add New Connection";
    } else {
        return connectionsLength >= 2 ? "Subscribe for additional connections" : "Add New Connection";
    }
}

const ConnectionPage: React.FC<{ setConnectionIndex: Dispatch<SetStateAction<number>> }> = ({setConnectionIndex}) => {
    const userProfileData = useUserProfileContext();
    const userData = useUserContext();
    const [connectionReferralURL, setConnectionReferralURL] = useState<string | null | undefined>("")
    const connectionIndex = useConnectionIndexContext()
    const isPremiumSubscriber = userProfileData.stripe_subscription_status === "active" || userProfileData.stripe_subscription_status === "Lifetime";
    const addNewText = evaluateConnectionLimit(userProfileData.connections.length, isPremiumSubscriber);
    const [isSaving, setIsSaving] = useState(false)
    const history = useHistory()

    const saveIndexToLocalStorage = (index: number) => {
        window.localStorage.setItem('connectionIndex', JSON.stringify(index));
    }

    const changeConnectionIndex = (index: number) => {
        saveIndexToLocalStorage(index)
        setConnectionIndex(index)
    }

    const addNewConnection = async () => {
        setIsSaving(true)
        const functions = getFunctions()
        const createUnconnectedConnection = httpsCallable(functions, "createUnconnectedConnectionCallable")
        await createUnconnectedConnection({uid: userData.uid, timezone: DateTime.now().zone.name})
        const auth = getAuth()
        await auth.currentUser?.getIdToken(true)
        setIsSaving(false)
    }

    const joinToConnection = async () => {
        let pathOnlyOfConnectionReferralURL = connectionReferralURL && connectionReferralURL[0] === "/" ? connectionReferralURL : connectionReferralURL?.split('/').splice(1).join('/')
        if (!pathOnlyOfConnectionReferralURL) {
            console.error("No connection referral URL.")
            return
        } else {
           history.push(pathOnlyOfConnectionReferralURL)
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Connections</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    userProfileData.connections.map((connectionDetails, index) => {
                        return (
                            <ConnectionDetailsComponent
                                key={connectionDetails.connectionId}
                                connectionDetails={connectionDetails}
                                isCurrentConnection={connectionIndex === index}
                                changeConnectionIndex={changeConnectionIndex}
                                onClick={() => {
                                    saveIndexToLocalStorage(index)
                                    setConnectionIndex(index);
                                }}/>)
                    })
                }
                {addNewText !== "Add New Connection" ?
                    <IonCard color={"dark"}>
                        <IonCardContent>
                            <IonCardTitle>{addNewText}</IonCardTitle>
                        </IonCardContent>
                    </IonCard>
                    :
                    <>
                        <IonCard disabled={isSaving} color={"dark"} onClick={addNewConnection}>
                            <IonCardContent>

                                <IonCardTitle>Add New Connection</IonCardTitle>
                                {isSaving ? <IonCardSubtitle><IonSpinner/>Adding new connection...</IonCardSubtitle> : null}
                            </IonCardContent>
                        </IonCard>
                        <IonCard color={"dark"}>
                            <IonCardContent>
                                <IonCardTitle>Join a Connection</IonCardTitle>
                                <IonLabel position={"stacked"}>Paste signup link below:</IonLabel>
                                <IonInput type={"text"} value={connectionReferralURL} onIonChange={(e) => {
                                    console.log("CHANGEd", e.detail.value)
                                    setConnectionReferralURL(e.detail.value)
                                }}/>
                                <IonButton onClick={joinToConnection}>
                                    Join
                                </IonButton>
                            </IonCardContent>
                        </IonCard>
                    </>


                }
            </IonContent>
        </IonPage>
    );
};

export default ConnectionPage;