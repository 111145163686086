import {
    IonContent,
    IonPage,
    IonTitle,
    IonItem,
    IonButton,
    IonText,
    IonHeader,
    IonToolbar
} from '@ionic/react';
import React from 'react';
import HomePageSwiper from "./HomePageSwiper";

const HomePage: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Obey247</IonTitle>
                    <IonButton routerLink="/login" slot={"end"}>Login</IonButton>
                    <IonButton routerLink="/signup" slot={"end"} color={"tertiary"}>Signup</IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center">
                <IonItem lines={"none"}/>
                <IonTitle><b>Keep Your <span style={{color: "pink"}}>boy</span> Obedient</b></IonTitle>
                <IonItem/>
                <IonItem lines={"none"}/>
                {/*<IonItem lines={"none"}>*/}
                {/*    <IonText>*/}
                {/*        <h3>For Chastity and Control Kinks</h3>*/}
                {/*        Obey247 is for people into chastity and control. Invite your Dom or sub, create rules and tasks to complete, enforce them with photo proof.*/}
                {/*    </IonText>*/}
                {/*</IonItem>*/}
                {/*<IonItem/>*/}
                <IonItem lines={"none"}>
                    <IonText>
                        <h3>What is Obey247?</h3>
                        Obey247 is a site to help friends hold each other accountable and form good habits.
                    </IonText>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonText>
                        <h3>How's it work?</h3>
                        Connect 1-on-1 with a friend.
                        You can chat, create tasks, and create rules (repeating tasks). The tasks are enforced
                        with photo proof, and come with notifications when a task is created or submitted.
                    </IonText>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonText>
                        <h3>For example</h3>
                        The person you connect with lives a few hours away, but you'd like to make them wakeup at 7am each
                        morning.
                        Create a daily rule for them to wakeup at 7am and take a picture of themselves as proof they're awake.
                        They'll be required to take a photo through the website and submit it for your approval.
                    </IonText>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonText>
                        <h3>Why be controlled?</h3>
                        Paradoxically, sometimes giving up freedom is freedom. When the "what" and
                        "why" are given to us, it frees us to be in the moment and embrace the experience.
                        Letting someone guide us and hold us accountable allows
                        us to be our best self.
                    </IonText>
                </IonItem>
                <IonItem lines={"none"}/>
                <HomePageSwiper/>
                <IonItem lines={"none"} color={"tertiary"} style={{borderRadius: "5px", marginTop: "25px"}}>
                    <IonText color={"light"}>Chat with us! support@obeyr.com</IonText>
                </IonItem>
            </IonContent>
        </IonPage>
    );
};

export default HomePage;