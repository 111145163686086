import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from 'firebase/app';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {environmentVariables} from "./obey247.config";
import {ReCaptchaV3Provider, initializeAppCheck} from "firebase/app-check";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

// See: https://firebase.google.com/docs/web/learn-more#config-object
// Initialize Firebase
const app = initializeApp(environmentVariables.firebaseConfig);
console.log("environment variables: ", environmentVariables)
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

// @ts-ignore
// eslint-disable-next-line no-restricted-globals
if (window.location.hostname === "localhost") self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeqDs4iAAAAALF24FAvoNiFNCDZyyZXGqYYU7DM'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);


defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// https://stackoverflow.com/questions/40612026/firebase-fcm-react-project-issue-firebase-messaging-sw-js-wrong-type
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(
        environmentVariables.environmentName === "PROD" ? '../firebase-messaging-sw.js' : '../firebase-messaging-sw-test.js'
    )
        .then(function (registration) {
            console.log('Registration successful, scope is:', registration.scope);
        }).catch(function (err) {
        console.log('Service worker registration failed, error:', err);
    });
}