import {
    IonBackButton,
    IonButtons,
    IonCard, IonCardSubtitle,
    IonContent,
    IonHeader,
    IonPage,
    IonSpinner,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, {useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {lockClosed} from "ionicons/icons";
import {IonItem, IonInput, IonButton, IonIcon, IonAlert} from '@ionic/react';
import {
    getAuth,
    sendEmailVerification,
 signInWithEmailAndPassword
} from "firebase/auth";
import {focusIonRefOnEnter, validateEmail} from "./UnAuthedHelperFunctions";
import {getFunctions, httpsCallable} from "firebase/functions";
import {useHistory} from "react-router-dom";
import {DateTime} from "luxon";


const SignupPage: React.FC = () => {
    const {
        urlRole,
        urlConnectionId
    } = useParams<{ urlRole: string | undefined, urlConnectionId: string | undefined }>();
    const isAnExistingConnection = !!(urlRole && urlConnectionId);

    const nameFieldRef = useRef<HTMLIonInputElement>(null);
    const emailFieldRef = useRef<HTMLIonInputElement>(null);
    const passwordFieldRef = useRef<HTMLIonInputElement>(null);
    const confirmPasswordFieldRef = useRef<HTMLIonInputElement>(null);

    const history = useHistory()

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const handleSignup = async () => {
        setIsSaving(true);
        if (!email) {
            console.log("Please enter a valid email");
            setMessage("Please enter a valid email");
            setIsError(true);
            setIsSaving(false);
            return;
        }
        if (validateEmail(email) === false) {
            console.log("Your email is invalid");
            setMessage("Your email is invalid");
            setIsError(true);
            setIsSaving(false);
            return;
        }

        if (!password || password.length < 6) {
            console.log("Please enter your password");
            setMessage("Please enter your password");
            setIsError(true);
            setIsSaving(false);
            return;
        }
        try {
            const functions = getFunctions()
            const signup = httpsCallable(functions, "signup")
            await signup({
                email: email,
                password: password,
                name: name,
                timezone: DateTime.now().zone.name,
                connectionId: urlConnectionId,
                originatorIsMaster: !!Number(urlRole)
            })
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password)
            if (auth.currentUser) {
                sendEmailVerification(auth.currentUser).then(() => console.log("Verification email sent!"))
            }
            history.push("/connections")
            setIsSaving(false);
        } catch (error: any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            setMessage(errorMessage);
            setIsError(true)
            setIsSaving(false);
        } finally {
            console.log("cleanup")
        }

    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>Signup</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center">

                <IonAlert
                    isOpen={isError}
                    onDidDismiss={() => setIsError(false)}
                    cssClass="my-custom-class"
                    header={"Error!"}
                    message={message}
                    buttons={["Dismiss"]}
                />

                <IonTitle><h1><b>Obey247</b></h1></IonTitle>
                <IonIcon
                    style={{fontSize: "70px",}}
                    icon={lockClosed}
                    color={"tertiary"}
                />
                <IonTitle><b>Keep Your <span style={{color: "pink"}}>boy</span> Obedient</b></IonTitle>
                <IonItem lines={"none"}/>

                <IonCard>
                    <IonItem>
                        <IonInput
                            label={"First name"}
                            labelPlacement={"floating"}
                            ref={nameFieldRef}
                            type="text"
                            value={name}
                            onIonInput={(e) => setName(e.detail.value!)}
                            onKeyDown={focusIonRefOnEnter(emailFieldRef)}
                        >
                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <IonInput
                            label={"Email"}
                            labelPlacement={"floating"}
                            ref={emailFieldRef}
                            type="email"
                            value={email}
                            onIonInput={(e) => setEmail(e.detail.value!)}
                            onKeyDown={focusIonRefOnEnter(passwordFieldRef)}
                        >
                        </IonInput>
                    </IonItem>
                </IonCard>
                <IonCard>
                    <IonItem>
                        <IonInput
                            label={"Password"}
                            labelPlacement={"floating"}
                            ref={passwordFieldRef}
                            type="password"
                            value={password}
                            onIonInput={(e) => setPassword(e.detail.value!)}
                            onKeyDown={focusIonRefOnEnter(confirmPasswordFieldRef)}
                        >
                        </IonInput>
                    </IonItem>

                    <IonItem>
                        <IonInput
                            label={"Confirm Password"}
                            labelPlacement={"floating"}
                            ref={confirmPasswordFieldRef}
                            type="password"
                            value={confirmPassword}
                            onIonInput={(e) => setConfirmPassword(e.detail.value!)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSignup();
                                }
                            }}
                        >
                        </IonInput>
                    </IonItem>
                </IonCard>
                <IonButton
                    disabled={isSaving}
                    expand="block"
                    onClick={handleSignup}
                    color={"tertiary"}
                >
                    Signup
                </IonButton>
                {
                    isSaving ?
                        <IonCardSubtitle>
                            Signing in. Please wait... <IonSpinner/>
                        </IonCardSubtitle>
                        :
                        null
                }
            </IonContent>
        </IonPage>
    );
};

export default SignupPage;