import {
    IonApp,
    setupIonicReact,
} from '@ionic/react';
import React, {useEffect, useState} from "react";
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './theme/variables.css';
import "./theme/global.css"
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
import LoadingPage from "./PrePageLoading/other_stuff/LoadingPage";
import UnAuthedRoutes from "./pages/UnAuthedEverything/UnAuthedRoutes";
import AuthedDataLoader from "./PrePageLoading/AuthedDataLoader";
import ErrorBoundary from "./global_components/ErrorBoundary";


setupIonicReact();

const App: React.FC = () => {
    // "User" is the authed user
    // "userData" is the firestore collection for the user
    console.log("Rendering App...")
    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (u) => {
            console.log("User: ", u)
            setUser(u);
            setIsInitializing(false);
        })
    }, [])

    if (isInitializing) {
        console.log("Initializing Auth...")
        return (
            <IonApp className={"app-root"}>
                <ErrorBoundary>
                    <LoadingPage/>
                </ErrorBoundary>
            </IonApp>
        )
    } else {
        if (user) {
            return (
                <IonApp className={"app-root"}>
                    <ErrorBoundary>
                        <AuthedDataLoader user={user}/>
                    </ErrorBoundary>
                </IonApp>
            );
        } else {
            return (
                <IonApp className={"app-root"}>
                    <ErrorBoundary>
                        <UnAuthedRoutes/>
                    </ErrorBoundary>
                </IonApp>
            );
        }
    }
}

export default App;
