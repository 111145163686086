import {IonSpinner} from "@ionic/react";
import "../css/LoadingPage.css";

const LoadingPage: React.FC = () => {
    return (
        <>
            <IonSpinner className={"loading-page-ion-spinner"} name={"dots"} color={"tertiary"}/>
        </>
    )
}

export default LoadingPage;