import {
    IonButton, IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader, IonItem,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import {useUserContext, useUserProfileContext} from "../../PrePageLoading/AuthedDataLoader";
import {environmentVariables} from "../../obey247.config";

const SubscriptionPage: React.FC = () => {
    const user = useUserContext();
    const userProfileData = useUserProfileContext();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Subscription</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines={"none"}>Subscription
                    status: {userProfileData.stripe_subscription_status || "Inactive"}
                </IonItem>
                <IonCard>
                    <IonCardContent>
                        {
                            userProfileData.stripe_customer_id && userProfileData.stripe_subscription_status !== "Lifetime" ?
                                <IonCardHeader>
                                    <IonCardTitle>
                                        Manage Billing Portal &gt;
                                    </IonCardTitle>
                                    <form
                                        action={`${environmentVariables.firebaseFunctionsDomainName}/createStripeBillingPortal`}
                                        method="POST"
                                        target={"_blank"}>
                                        {/* Add a hidden field with the lookup_key of your Price */}
                                        <input type="hidden"
                                               name="stripe_customer_id"
                                               value={userProfileData.stripe_customer_id}
                                        />
                                        <IonButton id="checkout-and-portal-button"
                                                   type="submit">
                                            Manage Billing
                                        </IonButton>
                                    </form>
                                </IonCardHeader>
                                : null
                        }
                    </IonCardContent>
                </IonCard>

                <IonCard>
                    {
                        userProfileData.stripe_subscription_status !== "active" &&
                        userProfileData.stripe_subscription_status !== "Lifetime" ?
                            <IonCardContent>
                                <IonCardHeader>
                                    <IonCardTitle>Monthly Subscription</IonCardTitle>
                                    <IonCardTitle>$1.99 / month</IonCardTitle>
                                    <form
                                        action={`${environmentVariables.firebaseFunctionsDomainName}/createStripeCheckoutSession`}
                                        method="POST"
                                        target={"_blank"}>
                                        <input type="hidden"
                                               name="uid"
                                               value={user.uid}
                                        />
                                        <input type="hidden"
                                               name="email"
                                               value={user.email!}
                                        />
                                        <IonButton id="checkout-and-portal-button" type="submit">
                                            Subscribe
                                        </IonButton>
                                    </form>
                                </IonCardHeader>
                            </IonCardContent>
                            : null
                    }
                    <IonCardContent>
                        <IonCardHeader>
                            <IonCardTitle>{userProfileData.stripe_subscription_status === "Lifetime" ?
                                "Thank you for your support!" :
                                "Lifetime Membership"}
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>{userProfileData.stripe_subscription_status === "Lifetime" ?
                            "Thank you for your support! :)" :
                            "Support this project with an amount of your choosing."}
                        </IonCardContent>
                        <form
                            action={`${environmentVariables.firebaseFunctionsDomainName}/createStripeCheckoutSessionLifetimeMembership`}
                            method="POST"
                            target={"_blank"}>
                            <input type="hidden"
                                   name="uid"
                                   value={user.uid}
                            />
                            <input type="hidden"
                                   name="email"
                                   value={user.email!}
                            />
                            <IonButton id="checkout-and-portal-button" type="submit">
                                Support Project
                            </IonButton>
                        </form>
                    </IonCardContent>
                </IonCard>

            </IonContent>
        </IonPage>
    );
};

export default SubscriptionPage;