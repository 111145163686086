import {Redirect, Route} from 'react-router-dom';
import {
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {
    chatbubble,
    checkmarkDone,
    lockClosed,
} from 'ionicons/icons';
import TasksPage from '../pages/1TasksPage/TasksPage';
import AccountPage from "../pages/AccountPage/AccountPage";
import NavMenuComponent from "../global_components/NavMenuComponent";
import ConnectionsPage from "../pages/ConnectionsPage/ConnectionsPage";
import SubscriptionPage from "../pages/SubscriptionPage/SubscriptionPage";
import FAQPage from "../pages/FAQPage/FAQPage";
import './css/AuthedRoutes.css';
import RulesPage from "../pages/2RulesPage/RulesPage";
import {Dispatch, SetStateAction} from "react";
import RefPage from "../pages/ConnectionsPage/RefPage";
import ChatPage from "../pages/3Chat/ChatPage";
import useTimezoneUpdater from "../custom_hooks/userTimezoneUpdater";
import {useConnectionDataContext} from "./AuthedDataLoader";

const AuthedRoutes: React.FC<{ connectionId: string, setConnectionIndex: Dispatch<SetStateAction<number>>, connectionIndex: number }> = ({connectionId, setConnectionIndex, connectionIndex}) => {
    const connectionData = useConnectionDataContext()
    const isPremiumConnection = !!(connectionData.Taskmaster_subscriber_status || connectionData.Taskdoer_subscriber_status);

    useTimezoneUpdater()

    return (
        <IonReactRouter>
            <NavMenuComponent/>
            <IonTabs key={connectionIndex}>
                <IonRouterOutlet id={"ion-router-outlet"}>
                    <Route exact path="/tasks/active">
                        <TasksPage
                            isActiveTabActive={true}
                            tasksData={connectionData.tasks}
                            timezone={connectionData.timezone}
                            isPremiumConnection={isPremiumConnection}
                        />
                    </Route>
                    <Route exact path="/tasks/completed">
                        <TasksPage
                            isActiveTabActive={false}
                            tasksData={connectionData.tasks}
                            timezone={connectionData.timezone}
                            isPremiumConnection={isPremiumConnection}
                        />
                    </Route>
                    <Route exact path="/rules">
                        <RulesPage
                            rulesData={connectionData.rules}
                            isPremiumConnection={isPremiumConnection}
                        />
                    </Route>
                    <Route exact path="/chat">
                        <ChatPage connectionId={connectionId}/>
                    </Route>
                    <Route exact path="/account">
                        <AccountPage
                        />
                    </Route>
                    <Route exact path="/connections">
                        <ConnectionsPage
                            setConnectionIndex={setConnectionIndex}
                        />
                    </Route>
                    <Route exact path="/subscription">
                        <SubscriptionPage
                        />
                    </Route>
                    <Route exact path="/faq">
                        <FAQPage/>
                    </Route>
                    <Route exact path="/ref/:urlRole/:urlConnectionId">
                        <RefPage/>
                    </Route>
                    <Redirect exact path="/" to="/tasks/active"/>
                    <Redirect exact path="" to="/tasks/active"/>
                    <Redirect exact path="/welcome" to="/tasks/active"/>
                    <Redirect exact path="/signup" to="/connections"/>
                </IonRouterOutlet>

                <IonTabBar slot="bottom" className={'bottom-tab-bar'}>
                    <IonTabButton tab="Tasks" href="/tasks/active">
                        <IonIcon icon={checkmarkDone}/>
                        <IonLabel>Tasks</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="Rules" href="/rules">
                        <IonIcon icon={lockClosed}/>
                        <IonLabel>Rules</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="Chat" href="/chat">
                        <IonIcon icon={chatbubble}/>
                        <IonLabel>Chat</IonLabel>
                    </IonTabButton>
                </IonTabBar>

            </IonTabs>
        </IonReactRouter>
    );
}

export default AuthedRoutes;
