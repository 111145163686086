import {
    IonButton,
    IonCol, IonContent, IonFooter, IonGrid,
    IonIcon, IonImg,
    IonItem,
    IonModal, IonRow, IonSpinner, IonTextarea,
    IonToolbar
} from "@ionic/react";
import {useRef} from "react";
import {
    arrowBack
} from "ionicons/icons";
import {SubmissionData} from "../../shared_with_firebase_functions/types";

interface IAddSubmissionModal extends SubmissionData {
    photo: string | undefined,
    isImageLoading: boolean
}

const AddSubmissionModal: React.FC<IAddSubmissionModal> = ({
                                                               submissionId,
                                                               submissionNote,
                                                               submissionSubmittalDatetime,
                                                               hasImage,
                                                               photo,
                                                               isImageLoading
                                                           }) => {

    const modal = useRef<HTMLIonModalElement>(null);
    const closeModal = () => {
        modal.current?.dismiss();
    }

    return (
        <IonModal ref={modal} trigger={`open-modal-submission-${submissionId}`}>
            <IonContent>
                <div className={photo ? "camera-area-no-border" : "camera-area"}>
                    {hasImage ?
                        (isImageLoading ?
                                <IonSpinner/>
                                :
                                <IonImg src={photo}/>
                        )
                        :
                        <IonItem lines={"none"}>No Photo Taken</IonItem>
                    }
                </div>
                <IonItem lines={"none"}>
                    <IonTextarea value={submissionNote}
                                 readonly={true}
                    />
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol size={"3"}>
                                <IonButton expand={"block"} color={"medium"} strong={true} size={"large"}
                                           onClick={closeModal}>
                                    <IonIcon icon={arrowBack}/>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default AddSubmissionModal;