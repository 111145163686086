import {IonCol, IonItem, IonRow, IonTextarea} from "@ionic/react";
import {ChatMessage} from "../../shared_with_firebase_functions/types";


const Message: React.FC<{ isFromPartner: boolean, messageData: ChatMessage }> = ({isFromPartner, messageData}) => {
    const offsetVal = isFromPartner ? "0" : "4"
    const color = isFromPartner ? "tertiary" : "primary"

    return (
        <IonRow>
            <IonCol offset={offsetVal} size={"8"}>
                <IonItem disabled={messageData.isSaving} style={{borderRadius:"12px"}} color={color} lines={"none"}>
                    <IonTextarea readonly={true} autoGrow={true} value={messageData.text}></IonTextarea>
                </IonItem>
            </IonCol>
        </IonRow>
    )
}

export default Message;