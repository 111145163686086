import {useIonToast} from "@ionic/react";

const useToast = () => {
    const [present] = useIonToast();
    const presentToast = (message: string) => {
        present({
            message: message,
            duration: 1000,
            position: "bottom"
        });
    };
    return {presentToast: presentToast};
}

export default useToast;