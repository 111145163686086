export const verifyIsString = (x: unknown) => {
    if (typeof x === 'string') {
        return x;
    } else throw new Error(`Input is not a string. Input : ${x}`);
}

export const verifyIsNumber = (x: unknown) => {
    if (typeof x === 'number') {
        return x;
    } else if (typeof x === 'string' && typeof Number(x) === 'number') {
        return Number(x);
    } else throw new Error(`Input is not a number. Input : ${x}`);
}

export const verifyIsBoolean = (x: unknown) => {
    if (typeof x === 'boolean') {
        return x;
    } else throw new Error(`Input is not a boolean. Input : ${x}`);
}