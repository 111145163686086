import {RuleData} from "../../shared_with_firebase_functions/types";

export const newRuleInitValues: RuleData = {
    title: "",
    description: "",
    due_date_offset_day: 0, // 0 is day of, 1 is tomorrow, etc.
    due_date_offset_time: "2000-01-01T12:00:00",
    recur_type: "DAILY",
    repeat_at_time: "2000-01-01T04:00:00",
    repeat_interval_days: 1,
    recur_days_of_week: {Su: false, M: false, T: false, W: false, Th: false, F: false, Sa: false},
}

export default newRuleInitValues;