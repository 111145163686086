import {

    IonContent, IonTabButton,
    IonHeader, IonLabel,
    IonPage, IonTabBar,
    IonTitle,
    IonToolbar, IonItem
} from '@ionic/react';
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import React from 'react';
import {
    useConnectionIndexContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import NewTaskButton from "./NewTaskButton";
import {TasksData} from "../../shared_with_firebase_functions/types";
import UncompletedTaskComponent from "./UncompletedTaskComponent";
import CompletedTasksComponent from "./CompletedTasksComponent";
import {DateTime} from "luxon";
import TaskdoerClock from "./TaskdoerClock";

const sortTasksByDueDate = (tasksData: TasksData) => {
    tasksData = structuredClone(tasksData)
    const entries = Object.entries(tasksData)
    const sorted = entries.sort((a, b) => {
        if (a[1].due_date === null && b[1].due_date === null) return 0
        if (a[1].due_date === null) return -1
        if (b[1].due_date === null) return 1
        return DateTime.fromISO(a[1].due_date).toUnixInteger() - DateTime.fromISO(b[1].due_date).toUnixInteger()
    })
    return sorted.map((arr) => arr[0])

}

interface TasksPageProps {
    tasksData: TasksData,
    isActiveTabActive: boolean,
    isPremiumConnection: boolean,
    timezone: string

}

const TasksPage: React.FC<TasksPageProps> = ({tasksData, isActiveTabActive, isPremiumConnection, timezone}) => {
    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];
    const isTaskmaster = thisConnectionDataFromProfile.role === "Taskmaster";
    const shouldDisplayTime = isTaskmaster && (timezone !== DateTime.now().zone.name)

    let activeTasksCount = Object.keys(tasksData).reduce(function (previousCount, currentId) {
        return (previousCount + ((tasksData[currentId].submittal_state === "SUBMITTED" || tasksData[currentId].submittal_state === "NOT SUBMITTED") ? 1 : 0));
    }, 0);

    console.log("Loading TasksPage. connectionIndex: ", connectionIndex)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Tasks / {thisConnectionDataFromProfile.partnerName || "unconnected"}</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>

            </IonHeader>
            <IonHeader>
                <IonTabBar>
                    <IonTabButton tab={"active"} href={"/tasks/active"}>
                        <IonLabel>Active</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab={"completed"} href={"/tasks/completed"}>
                        <IonLabel>Completed</IonLabel>
                    </IonTabButton>
                </IonTabBar>
            </IonHeader>

            {isActiveTabActive ?
                <>
                    <IonContent fullscreen>
                        {activeTasksCount > 0 ?
                            (sortTasksByDueDate(tasksData).map((taskId) => {
                                if (tasksData[taskId].submittal_state === "NOT SUBMITTED" ||
                                    tasksData[taskId].submittal_state === "SUBMITTED") {
                                    return <UncompletedTaskComponent taskData={tasksData[taskId]} key={taskId}
                                                                     taskId={taskId}/>
                                } else return null;
                            })) :
                            (
                                <IonItem style={{"marginTop": "20px"}} lines={"none"}>
                                    <IonTitle>
                                        <div className="ion-text-wrap">
                                            {isTaskmaster ? "No tasks created yet." : "The Taskmaster hasn't created any tasks for you yet."}
                                        </div>
                                    </IonTitle>
                                </IonItem>
                            )
                        }

                        {isTaskmaster ?
                            <NewTaskButton
                                tasksLength={activeTasksCount}
                                isPremiumConnection={isPremiumConnection}

                            />
                            : null
                        }
                    </IonContent>
                    {shouldDisplayTime ? <TaskdoerClock timezone={timezone}/> : null}

                </>
                :
                <IonContent fullscreen>
                    <CompletedTasksComponent connectionId={thisConnectionDataFromProfile.connectionId}/>
                </IonContent>
            }
        </IonPage>
    );
};

export default TasksPage;
