import {IonFooter, IonItem} from "@ionic/react";
import {DateTime} from "luxon";
import React, {useCallback, useEffect, useState} from "react";

const ONE_MINUTE = 60 * 1000

const TaskdoerClock: React.FC<{ timezone: string }> = ({timezone}) => {
    const getTime = useCallback(() => DateTime.now().setZone(timezone).toFormat("hh:mm a"), [timezone])
    const [time, setTime] = useState(getTime())

    useEffect(() => {
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTime(getTime());
        }, ONE_MINUTE);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [time, getTime]);

    return (
        <IonFooter>
            <IonItem>Taskdoer Time: {time}</IonItem>
        </IonFooter>
    )

}

export default TaskdoerClock;