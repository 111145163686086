import AuthedRoutes from "./AuthedRoutes";
import {useContext, useEffect, useState} from "react";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";
import LoadingPage from "./other_stuff/LoadingPage";
import React from "react";
import initializeFirebaseCloudMessaging from "./other_stuff/initializeFirebaseCloudMessaging";
import {ConnectionData, UserProfileData} from "../shared_with_firebase_functions/types";
import {User} from "firebase/auth";
import logClientSideError from "../utility_functions/logClientSideError";

export const UserContext = React.createContext<User | undefined>(undefined);
export const useUserContext = () => {
    let userContext = useContext(UserContext);
    if (userContext === undefined) {
        throw new Error(
            "User Context has not yet been initialized."
        )
    }
    return userContext;
}

export const UserProfileContext = React.createContext<UserProfileData | undefined>(undefined);
export const useUserProfileContext = () => {
    let userProfileContext = useContext(UserProfileContext);
    if (userProfileContext === undefined) {
        throw new Error(
            "User Profile Context has not yet been initialized."
        )
    }
    return userProfileContext;
}

export const ConnectionIndexContext = React.createContext<number | undefined>(undefined);
export const useConnectionIndexContext = () => {
    let connectionIndexContext = useContext(ConnectionIndexContext);
    if (connectionIndexContext === undefined) {
        throw new Error(
            "Current Connection Index Context has not yet been initialized."
        )
    }
    return connectionIndexContext;
}

export const ConnectionDataContext = React.createContext<ConnectionData | undefined>(undefined);
export const useConnectionDataContext = () => {
    let connectionDataContext = useContext(ConnectionDataContext);
    if (connectionDataContext === undefined) {
        throw new Error(
            "Current Connection Data Context has not yet been initialized."
        )
    }
    return connectionDataContext;
}

const getConnectionIndexFromLocalStorage: () => number | null = () => {
    const storedIndex = window.localStorage.getItem('connectionIndex')
    return JSON.parse(storedIndex || "null")
}



const AuthedDataLoader: React.FC<{ user: User }> = ({user}) => {
    console.log("Logged in, Loading data...")
    const [userProfileData, setUserProfileData] = useState<UserProfileData>();
    const [connectionIndex, setConnectionIndex] = useState(getConnectionIndexFromLocalStorage() || 0);
    const [connectionData, setConnectionData] = useState<ConnectionData>();
    console.log(connectionIndex)


    const db = getFirestore();

    useEffect(() => {
        const userDocRef = doc(db, "users", user.uid);
        const loadUserProfileData = () => {
            return onSnapshot(userDocRef, (doc) => {
                setUserProfileData(doc.data() as UserProfileData)
            }, (error) => {
                logClientSideError(error)
            })
        }
        return loadUserProfileData();

    }, [user])

    useEffect(() => {
        if (userProfileData?.connections) {
            const connectionId = userProfileData.connections[connectionIndex]?.connectionId
            // Reset index to 0 if it doesn't exist here
            if (!connectionId && connectionIndex) {
                setConnectionIndex(0)
                return
            }
            const connectionDocRef = doc(db, "connections", connectionId);
            const loadConnectionData = () => {
                return onSnapshot(connectionDocRef, (doc) => {
                    setConnectionData(doc.data() as ConnectionData)
                }, (error) => {
                    logClientSideError(error)
                })
            }
            return loadConnectionData();
        }
    }, [userProfileData, connectionIndex])

    useEffect(() => {
        initializeFirebaseCloudMessaging(user.uid);
    }, [user]);

    if (user && userProfileData && connectionData) return (
        <UserContext.Provider value={user}>
            <UserProfileContext.Provider value={userProfileData}>
                <ConnectionIndexContext.Provider value={connectionIndex}>
                    <ConnectionDataContext.Provider value={connectionData}>
                        <AuthedRoutes connectionId={userProfileData.connections[connectionIndex].connectionId}
                                      setConnectionIndex={setConnectionIndex}
                                      connectionIndex={connectionIndex}
                        />
                    </ConnectionDataContext.Provider>
                </ConnectionIndexContext.Provider>
            </UserProfileContext.Provider>
        </UserContext.Provider>

    )
    else return (<LoadingPage/>)
}

export default AuthedDataLoader;