import {
    IonContent,
    IonPage,
    IonSpinner,
    IonTitle,
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    IonAlert,
    IonFooter,
    IonText,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton
} from '@ionic/react';
import React, {useRef, useState} from 'react';
import {lockClosed} from "ionicons/icons";
import {getAuth, sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import {focusRefOnEnter, validateEmail} from "./UnAuthedHelperFunctions";


const LoginPage: React.FC = () => {
    const passwordFieldRef = useRef<any>(null);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isError, setIsError] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const handleError = (message: string) => {
        setMessage(message);
        setIsError(true);
        setIsLoggingIn(false);
        console.log("Error: ", message);
    }
    const handleLogin = () => {
        setIsLoggingIn(true);
        if (!email) {
            handleError("Please enter a valid email");
            return;
        }
        if (!validateEmail(email)) {
            handleError("Your email is invalid");
            return;
        }
        if (!password || password.length < 6) {
            handleError("Please enter a valid password");
            return;
        }
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in. The app now gets re-rendered by the authed routes.
                setIsLoggingIn(false);
            })
            .catch((error) => {
                const errorMessage = error.code + error.message;
                handleError(errorMessage);
            });
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center">
                <IonAlert
                    isOpen={isError}
                    onDidDismiss={() => setIsError(false)}
                    cssClass="my-custom-class"
                    header={"Error!"}
                    message={message}
                    buttons={["Dismiss"]}
                />

                <IonIcon
                    style={{fontSize: "70px",}}
                    icon={lockClosed}
                    color={"tertiary"}
                />
                <IonTitle><b>Keep Your <span style={{color:"pink"}}>boy</span> Obedient</b></IonTitle>
                <IonItem lines={"none"} />


                <IonItem>
                    <IonInput
                        label={"Email"}
                        labelPlacement={"floating"}
                        type="email"
                        value={email}
                        onIonChange={(e) => setEmail(e.detail.value!)}
                        onKeyDown={focusRefOnEnter(passwordFieldRef)}
                    >
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonInput
                        label={"Password"}
                        labelPlacement={"floating"}
                        ref={passwordFieldRef}
                        type="password"
                        value={password}
                        onIonInput={(e) => {
                            setPassword(e.detail.value!)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleLogin();
                            }
                        }}
                    >
                    </IonInput>
                </IonItem>

                <IonButton disabled={isLoggingIn} expand={"block"} onClick={handleLogin}>{isLoggingIn ?
                    <IonSpinner/> : "Login"}</IonButton>
                <IonButton expand={"block"} color={"tertiary"} routerDirection={"forward"}
                           routerLink={"/signup"}>Signup</IonButton>
                <IonItem>
                    <IonButton size={"small"}
                               slot={"start"}
                               onClick={() => {
                                   const auth = getAuth();
                                   sendPasswordResetEmail(auth, email)
                                       .then(() => {
                                           console.log("Password reset email sent!")
                                       })
                                       .catch((error) => {
                                           const errorCode = error.code;
                                           const errorMessage = error.message;
                                           console.log("Error sending password reset email: ", errorMessage)
                                           // ..
                                       });
                               }}
                    >
                        Forgot Password
                    </IonButton>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonItem color={"tertiary"}>
                    <IonText>Questions? Contact support@obeyr.com</IonText>
                </IonItem>
            </IonFooter>
        </IonPage>
    );
};

export default LoginPage;