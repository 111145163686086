import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle, IonIcon, IonLabel,
    IonSegment,
    IonSegmentButton, IonSpinner
} from "@ionic/react";
import {ProfileConnectionData} from "../../shared_with_firebase_functions/types";
import {useEffect, useState} from "react";
import {
    copyOutline,
} from "ionicons/icons";
import {verifyIsString} from "../../shared_with_firebase_functions/verifyInputs";
import ConnectionDetailsComponentTitle from "./ConnectionDetailsComponentTitle";
import {getFunctions, httpsCallable} from "firebase/functions";

interface ConnectionDetailComponentProps {
    connectionDetails: ProfileConnectionData,
    isCurrentConnection: boolean,
    onClick: () => void,
    changeConnectionIndex: (index: number) => void
}

const ConnectionDetailsComponent: React.FC<ConnectionDetailComponentProps> = ({
                                                                                  connectionDetails,
                                                                                  isCurrentConnection,
                                                                                  onClick,
                                                                                  changeConnectionIndex
                                                                              }) => {
    const currentDomainName = window.location.href.split("/")[2]
    const [shareableURLPathName, setShareableURLPathName] = useState<string | undefined>();
    const [currentlySelectedRole, setCurrentlySelectedRole] = useState<string | undefined>(connectionDetails.role);
    const [isDeleting, setIsDeleting] = useState(false)

    const deleteConnectionHandler = async () => {
        setIsDeleting(true)
        changeConnectionIndex(0)
        const functions = getFunctions()
        const deleteConnection = httpsCallable(functions, "deleteConnection")
        await deleteConnection(connectionDetails.connectionId);
        console.log("IS DELETING", isDeleting)
        setIsDeleting(false)
    }

    useEffect(() => {
        const shareableRole = (currentlySelectedRole === "Taskmaster" ? 1 : 0);
        setShareableURLPathName(`ref/` +
            `${shareableRole}/` +
            `${connectionDetails.connectionId}`)
    }, [currentlySelectedRole])

    return (
        <IonCard disabled={isDeleting} color={isCurrentConnection ? "success" : ""}
                 onClick={onClick}>
            <IonCardContent>
                {connectionDetails.partnerName ?
                    <IonCardHeader>
                        <ConnectionDetailsComponentTitle
                            titleText={`Connection with: ${connectionDetails.partnerName}`}
                            connectionId={connectionDetails.connectionId}
                            deleteConnectionHandler={deleteConnectionHandler}
                            partnerName={connectionDetails.partnerName}
                        />
                        <IonCardTitle>Your role: {connectionDetails.role}</IonCardTitle>
                        {isDeleting ? <IonCardSubtitle><IonSpinner/>Deleting connection...</IonCardSubtitle> : null}
                    </IonCardHeader>
                    :
                    <IonCardHeader>
                        <ConnectionDetailsComponentTitle titleText={`You're not connected with anyone yet!`}
                                                         connectionId={connectionDetails.connectionId}
                                                         deleteConnectionHandler={deleteConnectionHandler}
                                                         partnerName={connectionDetails.partnerName}
                        />
                        {isDeleting ? <IonCardSubtitle><IonSpinner/>Deleting connection...</IonCardSubtitle> :
                            <IonCardSubtitle>
                                Choose your role. Then share the link to connect.
                            </IonCardSubtitle>}

                        <IonCardTitle>
                            <IonSegment
                                value={currentlySelectedRole}
                                onIonChange={e => {
                                    setCurrentlySelectedRole(verifyIsString(e.detail.value));
                                }}
                            >
                                <IonSegmentButton value="Taskmaster">
                                    <IonLabel>Taskmaster</IonLabel>
                                </IonSegmentButton>
                                <IonSegmentButton value="Taskdoer">
                                    <IonLabel>Taskdoer</IonLabel>
                                </IonSegmentButton>
                            </IonSegment>
                        </IonCardTitle>
                        <IonCardTitle>Share this link:</IonCardTitle>
                        <IonButton onClick={() => {
                            navigator.clipboard.writeText(`${currentDomainName}/${shareableURLPathName}`)
                        }}>
                            <IonIcon icon={copyOutline}/>
                        </IonButton>
                        <IonCardSubtitle
                            style={{"userSelect": "auto"}}>{currentDomainName}/{shareableURLPathName}</IonCardSubtitle>
                    </IonCardHeader>
                }
            </IonCardContent>
        </IonCard>
    )
}

export default ConnectionDetailsComponent;