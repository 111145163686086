import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import {environmentVariables} from "../../obey247.config";


const initializeFirebaseCloudMessaging = async (uid: string) => {
    const firebaseConfig = environmentVariables.firebaseConfig;
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    function requestPermission() {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Have permission');
                return getToken(messaging, {
                    vapidKey:
                    environmentVariables.environmentName === "PROD" ?
                        "BGHP2g-VvLOzk6AfwJqtBSkHGJv8JcWfCbYN4tbR7zw9Iq8JvBqgmRfUJAtPkIS_tGkbt2saNgp-gZEugtD2OuQ" :
                        "BD2lNDbHtZdBI_H_rhdrBfeRtWKunD03BlyIH9bBTbgkssLVr7pWzRKG2NqdzX1ATgC9kiJBvYBGJq74GoKb8tY"
                }).then(
                    async (currentToken) => {
                        if (currentToken) {
                            // Send the token to your server and update the UI if necessary
                            console.log("Firebase token: ", currentToken);
                            const db = getFirestore();

                            await setDoc(doc(db, "users", uid), {
                                "notificationTokens": {
                                    [currentToken]: true,
                                }

                            }, {merge: true})
                        } else {
                            // Show permission request UI
                            console.log('No registration token available. Request permission to generate one.');
                            // ...
                        }
                    }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });
            }
        })
    }

    await requestPermission();

    onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        // ...
    });
};

export default initializeFirebaseCloudMessaging;