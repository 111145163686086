import {
    IonButton,
    IonCardContent, IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader, IonIcon, IonItem,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import LogoutButton from "./LogoutButton";
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import {useUserContext, useUserProfileContext} from "../../PrePageLoading/AuthedDataLoader";
import {pencil} from "ionicons/icons";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";

const AccountPage: React.FC = () => {
    const userProfileData = useUserProfileContext();
    const userData = useUserContext();
    console.log("user data", userData)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Account</IonTitle>
                    <LogoutButton/>
                    <NavMenuButtonComponent/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCardContent>
                    <IonCardHeader>
                        <IonItem>
                            <IonCardTitle>Username: {userProfileData.name}</IonCardTitle>
                        </IonItem>
                        <IonItem>
                            <IonCardTitle>Email: {userData.email}</IonCardTitle>
                        </IonItem>

                    </IonCardHeader>
                    <IonButton size={"small"}
                               onClick={() => {
                                   const auth = getAuth();
                                   sendPasswordResetEmail(auth, auth.currentUser!.email!)
                                       .then(() => {
                                           console.log("Password reset email sent!")
                                       })
                                       .catch((error) => {
                                           const errorCode = error.code;
                                           const errorMessage = error.message;
                                           console.log("Error sending password reset email: ", errorMessage)
                                           // ..
                                       });
                               }}
                    >
                        <IonIcon icon={pencil}/>Send Password Reset Email
                    </IonButton>

                </IonCardContent>
            </IonContent>
        </IonPage>
    );
};

export default AccountPage;
