import {
    IonContent,
    IonGrid,
    IonHeader,
    IonPage, IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import Message from "./Message";
import TypingArea from "./TypingArea";
import {createRef, useCallback, useEffect, useState} from "react";
import {ChatMessage} from "../../shared_with_firebase_functions/types";
import {
    useConnectionIndexContext,
    useUserContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import {collection, getFirestore, onSnapshot, query, orderBy, limit} from "firebase/firestore";

const ChatPage: React.FC<{ connectionId: string }> = ({connectionId}) => {
    const [messages, setMessages] = useState<ChatMessage[]>()
    const [isLoading, setIsLoading] = useState(true)
    const user = useUserContext()
    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];

    const contentRef = createRef<HTMLIonContentElement>();

    useEffect(() => {
        const db = getFirestore()
        const chatMessagesRef = collection(db, "connections", connectionId, "chat_messages")
        const q = query(chatMessagesRef, orderBy("timeSent", "desc"), limit(15));
        const unsubscribe = onSnapshot(q, {includeMetadataChanges: true}, querySnapshot => {
            const messages: ChatMessage[] = [];
            querySnapshot.forEach((doc) => {
                console.log("IS DATA SAVING? ", doc.metadata.hasPendingWrites)
                const messageData = doc.data() as ChatMessage
                messageData.isSaving = doc.metadata.hasPendingWrites
                messages.push(messageData);
            });
            messages.reverse()
            setMessages(messages)
            setIsLoading(false)
        })

        return () => unsubscribe()
    }, [connectionId]);

    const scrollToBottom = useCallback(() => {
        contentRef.current?.scrollToBottom(200);
        }, [contentRef])

    useEffect(() => {
        scrollToBottom();
    }, [scrollToBottom])


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Chat / {thisConnectionDataFromProfile.partnerName || "unconnected"}</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>
            </IonHeader>
            <IonContent ref={contentRef}>
                { isLoading ? <IonSpinner className={"loading-page-ion-spinner"} /> : <IonGrid>
                    <div style={{overflow: "auto", display: "flex", flexDirection: "column-reverse"}}>
                        <div>
                            {
                                messages?.map((message) => {
                                    console.log("MESSAGE", message)
                                    return (<Message isFromPartner={message.senderUid !== user.uid}
                                                     messageData={message}/>)
                                })
                            }
                        </div>
                    </div>
                </IonGrid>}
            </IonContent>
            <TypingArea scrollToBottom={scrollToBottom}/>

        </IonPage>

    )
}

export default ChatPage;