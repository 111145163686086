import {
    IonButton,
    IonCol, IonContent, IonFooter, IonGrid,
    IonIcon, IonImg,
    IonItem,
    IonModal, IonRow, IonSpinner, IonTextarea,
    IonToolbar
} from "@ionic/react";
import {useRef, useState} from "react";
import {cameraOutline, checkmark, closeOutline} from "ionicons/icons";
import useToast from "../../custom_hooks/useToast";
import {doc, getFirestore, setDoc, arrayUnion} from "firebase/firestore";
import generateUniqueKey from "../../shared_with_firebase_functions/generateUniqueKey";
import useSubmissionPhotos from "../../custom_hooks/useSubmissionPhotos";
import {SubmissionData} from "../../shared_with_firebase_functions/types";

const AddSubmissionModal: React.FC<{ connectionId: string, taskId: string }> = ({connectionId, taskId}) => {
    const modal = useRef<HTMLIonModalElement>(null);

    const [submissionNote, setSubmissionNote] = useState<string | null | undefined>("")
    const [submissionId, setSubmissionId] = useState(generateUniqueKey())
    const {presentToast} = useToast();
    const {
        takePhoto,
        photo,
        setPhoto,
        saveToFirebaseStorage
    } = useSubmissionPhotos(presentToast, connectionId, taskId, submissionId);
    const [isSaving, setIsSaving] = useState(false);

    const resetModalState = () => {
        setSubmissionNote("");
        setPhoto(null);
        setSubmissionId(generateUniqueKey());
    }

    const closeModal = () => {
        resetModalState();
        modal.current?.dismiss();
    }

    const saveSubmission = async () => {
        setIsSaving(true);
        let db = getFirestore();
        if (photo) {
            await saveToFirebaseStorage(photo);
        }
        await setDoc(doc(db, "connections", connectionId), {
                tasks: {
                    [taskId]: {
                        submissions: arrayUnion({
                            submissionId: submissionId,
                            submissionNote: submissionNote,
                            submissionSubmittalDatetime: Date.now(),
                            hasImage: !!photo
                        } as SubmissionData)
                    }
                }

            },
            {merge: true})
        closeModal();
        setIsSaving(false);
    }

    return (
        <IonModal ref={modal} trigger={`open-modal-submission-123`}>
            <IonContent>
                <div className={photo ? "camera-area-no-border" : "camera-area"}>
                    {photo ?
                        <IonImg src={photo.webPath}/>
                        :
                        <IonButton expand={"block"}
                                   size={"large"}
                                   onClick={takePhoto}
                        >
                            <IonIcon icon={cameraOutline}></IonIcon>
                        </IonButton>
                    }
                </div>
                <IonItem lines={"none"}>
                    <IonTextarea value={submissionNote}
                                 placeholder={"Add a note..."}
                                 onIonChange={(e) => {
                                     setSubmissionNote(e.detail.value)
                                 }}
                    />
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <IonGrid>
                        <IonRow>
                            <IonCol size={"3"}>
                                <IonButton disabled={isSaving} expand={"block"} color={"danger"} strong={true}
                                           size={"large"}
                                           onClick={closeModal}>
                                    <IonIcon icon={closeOutline}/>
                                </IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton disabled={isSaving} expand={"block"} color={"success"} strong={true}
                                           size={"large"}
                                           onClick={saveSubmission}>
                                    {isSaving ? <IonSpinner/> : <IonIcon icon={checkmark}/>}&nbsp;Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    )
}

export default AddSubmissionModal;
