export let environmentVariables: any;

const checkIfInProduction = (): boolean => {
    const hostname = window.location.hostname;
    return hostname === "taskobeyr.com" || hostname === "www.taskobeyr.com" || hostname === "obey247.com";
}

const isProduction = checkIfInProduction();

if (isProduction) {
    environmentVariables = {
        environmentName: "PROD",
        firebaseFunctionsDomainName: "https://us-central1-obeyr-prod.cloudfunctions.net",
        firebaseConfig: {
            apiKey: "AIzaSyB8jtAvaySi7mkAoJkFgrxafLEI94xQkgs",
            authDomain: "obeyr-prod.firebaseapp.com",
            projectId: "obeyr-prod",
            storageBucket: "obeyr-prod.appspot.com",
            messagingSenderId: "707786876488",
            appId: "1:707786876488:web:0b49a86b71ef021363da7d",
            measurementId: "G-2HPM2VBMXE"
        }

    }
}

else {
    environmentVariables = {
        environmentName: "TEST",
        firebaseFunctionsDomainName: "https://us-central1-s-accountability-app.cloudfunctions.net",
        firebaseConfig: {
            apiKey: "AIzaSyAFAg306uLVGRMSYv1ulTT6u-qbPuItwJE",
            authDomain: "fir-accountability-app.firebaseapp.com",
            projectId: "s-accountability-app",
            storageBucket: "s-accountability-app.appspot.com",
            messagingSenderId: "129157662686",
            appId: "1:129157662686:web:89794f1ac72ab012c27f4f"
        }

    }
}