import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonSpinner} from "@ionic/react";
import React, {useState} from "react";
import UncompletedTaskModal from "./UncompletedTaskModal";
import newTaskInitValues from "./newTaskInitValues";
import {collection, doc, getFirestore} from "firebase/firestore";
import {DateTime} from "luxon";

const titleText = (tasksLength: number, isPremiumConnection: boolean) => {
    if (isPremiumConnection) {
        return tasksLength >= 20 ? "Task Limit Reached" : "Add New Task";
    } else {
        return tasksLength >= 5 ? "Subscribe for additional tasks" : "Add New Task";
    }
}

const generateId = () => {
    let db = getFirestore();
    return doc(collection(db, "recurring_tasks")).id
}

const NewTaskButton:React.FC<{tasksLength: number, isPremiumConnection: boolean}> = ({tasksLength, isPremiumConnection}) => {
    const [newModalTaskId, setNewModalTaskId] = useState(generateId())
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <>
            <IonCard disabled={isSaving || isDeleting || titleText(tasksLength, isPremiumConnection) !== "Add New Task"}
                     color={"dark"} id={`open-modal-${newModalTaskId}`} button={true}
            >
                <IonCardContent>
                    <IonCardTitle>
                        {titleText(tasksLength, isPremiumConnection)}
                    </IonCardTitle>
                    {isSaving ?
                        <IonCardSubtitle>
                            <IonSpinner />
                            Creating new task...
                        </IonCardSubtitle> : null
                    }
                </IonCardContent>
            </IonCard>

            <UncompletedTaskModal
                key={newModalTaskId}
                taskData={{...newTaskInitValues, due_date: DateTime.now().plus({day: 1}).toISO({includeOffset: false})}}
                taskId={newModalTaskId}
                resetInitValues={() => {
                    setNewModalTaskId(generateId());
                }}
                setIsSaving={setIsSaving}
                setIsDeleting={setIsDeleting}
            />

        </>
    )
}

export default NewTaskButton;