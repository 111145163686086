import {useState} from 'react';
import {Camera, CameraResultType, CameraSource, Photo} from '@capacitor/camera';
import {getBlob, getStorage, ref, uploadBytes} from "firebase/storage";
import logClientSideError from "../utility_functions/logClientSideError";

export function useSubmissionPhotos(presentToast: (message: string) => void, connectionId: string, taskId: string, submissionId: string) {
    const [photo, setPhoto] = useState<Photo | null>(null);
    const [isImageLoading, setIsImageLoading] = useState(true);

    const loadPhoto = async () => {
        const storage = getStorage();
        const itemRef = ref(storage, `taskImages/${connectionId}/${taskId}/${submissionId}`);
        const blobData = await getBlob(itemRef)
        let blobUrl = URL.createObjectURL(blobData);
        setIsImageLoading(false);
        return blobUrl
    }

    async function saveToFirebaseStorage(photo: Photo) {
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();
        const storage = getStorage();
        const storageRef = ref(storage, `taskImages/${connectionId}/${taskId}/${submissionId}`);
        try {
            await uploadBytes(storageRef, blob);
            presentToast("Photo saved");
        } catch (e: any) {
            presentToast(`Error saving image: ${e.code}`);
            console.log("Error saving image", e);
            logClientSideError(e);
            return false;
        }
        return true;
    }

    const takePhoto = async () => {
        let photo;
        try {
            photo = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                webUseInput: false,
                source: CameraSource.Camera,
                quality: 70,
            });
        } catch (e: any) {
            console.log("Error taking photo", e);
            logClientSideError(e);
        }
        if (photo) {
            setPhoto(photo);
        }
    };

    return {
        photo,
        setPhoto,
        saveToFirebaseStorage,
        takePhoto,
        isImageLoading,
        loadPhoto
    };
}

export default useSubmissionPhotos;