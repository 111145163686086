import {IonButton, IonButtons} from '@ionic/react'
import {useHistory} from "react-router-dom";
import {getAuth, signOut} from "firebase/auth";

const logUserOut = (history: any) => {
    const auth = getAuth();
    signOut(auth).then(() => {
        history.push(`/`);
    }).catch((error) => {
        console.log("Error: ", error);
        // An error happened.
    });
}

const LogoutButton: React.FC = () => {
    const history = useHistory();
    return (
        <>
            <IonButtons slot={"end"}>
                <IonButton onClick={async () => {
                    logUserOut(history);
                }}>Logout</IonButton>
            </IonButtons>
        </>
    )
}

export default LogoutButton;