import React, {useState} from "react";
import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonSpinner} from "@ionic/react";
import RuleModal from "./RuleModal";
import {RuleData} from "../../shared_with_firebase_functions/types";

const RuleComponent: React.FC<{ ruleData: RuleData, ruleId: any }> = ({ruleData, ruleId}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const isPaused = ruleData.recur_type === "DONT_REPEAT"

    return (
        <React.Fragment>
            <IonCard disabled={isSaving || isDeleting} id={`open-modal-${ruleId}`} button={true}
                     color={isPaused ? "secondary" : undefined}>
                <IonCardContent>
                    <IonCardTitle>
                        {ruleData.title}
                    </IonCardTitle>
                    <IonCardSubtitle>
                        {ruleData.description}
                    </IonCardSubtitle>
                    {isPaused ?
                        <IonCardSubtitle>
                            Paused
                        </IonCardSubtitle>
                        :
                        null}
                    {isDeleting ?
                        <IonCardSubtitle>
                            <IonSpinner/>
                            Deleting...
                        </IonCardSubtitle>
                        : null}
                    {isSaving ?
                        <IonCardSubtitle>
                            <IonSpinner/>
                            Saving...
                        </IonCardSubtitle>
                        : null}

                </IonCardContent>
            </IonCard>
            <RuleModal ruleData={ruleData} ruleId={ruleId}
                       setIsSaving={setIsSaving}
                       setIsDeleting={setIsDeleting}
                       isNewRule={false}
            />
        </React.Fragment>
    )
}

export default RuleComponent;