import {TaskData} from "../../shared_with_firebase_functions/types";
import {DateTime} from "luxon";

export const newTaskInitValues: TaskData = {
    title: "",
    description: "",
    due_date: DateTime.now().plus({day: 1}).toISO({includeOffset: false}),
    submittal_state: "NOT SUBMITTED",
    submissions: []
}

export default newTaskInitValues;