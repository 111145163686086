import CompletedTaskComponent from "./CompletedTaskComponent";
import React, {useEffect, useState} from "react";
import {CompletedTasksData} from "../../shared_with_firebase_functions/types";
import {doc, getFirestore, onSnapshot} from "firebase/firestore";

interface ICompletedTaskComponents {
    connectionId: string,
}

export const CompletedTasksComponent: React.FC<ICompletedTaskComponents> = ({connectionId}) => {
    const [completedTasksData, setCompletedTasksData] = useState<CompletedTasksData | undefined>(undefined);

    useEffect(() => {
        const db = getFirestore();
        const completedTasksRef = doc(db, "completed_tasks", connectionId);
        const loadCompletedTasks = async () => {
            const unsub = onSnapshot(completedTasksRef, (doc) => {
                let preReversedTasksData = doc.data() as CompletedTasksData
                preReversedTasksData.completed_tasks.reverse()
                setCompletedTasksData(preReversedTasksData)
            })
            return unsub;
        }
        loadCompletedTasks().catch(console.error)
    }, []);

    return (
        <>
        {completedTasksData?.completed_tasks.map((taskData) => {
                    return <CompletedTaskComponent connectionId={connectionId} taskData={taskData} key={taskData.taskId} taskId={taskData.taskId}/>
            })}
        </>
    )

}

export default CompletedTasksComponent;