import {
    IonButton,
    IonButtons, IonCard,
    IonContent, IonDatetime, IonDatetimeButton, IonFooter,
    IonHeader, IonIcon, IonInput,
    IonItem,
    IonLabel,
    IonModal, IonTextarea,
    IonToolbar
} from "@ionic/react";
import {useRef, useState} from "react";
import {
    doc,
    getFirestore,
    setDoc,
    writeBatch,
    deleteField, arrayUnion,
} from "firebase/firestore";
import {
    addOutline,
    arrowBackOutline,
    calendar,
    checkmark, closeOutline,
    saveOutline,
    trashBinOutline
} from "ionicons/icons";
import {
    useConnectionIndexContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import {getFunctions, httpsCallable} from "firebase/functions";
import {CompletedTaskData, TaskData, TaskSubmittalState} from "../../shared_with_firebase_functions/types";
import {verifyIsString} from "../../shared_with_firebase_functions/verifyInputs";
import logClientSideError from "../../utility_functions/logClientSideError";
import useToast from "../../custom_hooks/useToast";
import AddSubmissionModal from "./AddSubmissionModal";
import SubmissionComponent from "./SubmissionComponent";

const removeTimezoneFromISO = (isoString: string) => {
    return isoString.split(".")[0]
}

export const UncompletedTaskModal: React.FC<{
    taskData: TaskData,
    taskId: string,
    setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
    setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>,
    resetInitValues?: () => void
}> = ({
          taskData,
          taskId,
          setIsSaving,
          setIsDeleting,
          resetInitValues
      }) => {

    const [title, setTitle] = useState(taskData.title);
    const [description, setDescription] = useState(taskData.description);
    const [dueDate, setDueDate] = useState(taskData.due_date);
    const modal = useRef<HTMLIonModalElement>(null);

    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];
    const isTaskmaster = thisConnectionDataFromProfile.role === "Taskmaster";
    const isTaskdoer = thisConnectionDataFromProfile.role === "Taskdoer";

    const {presentToast} = useToast();

    async function saveTaskData() {
        let db = getFirestore();

        // Save task data
        await setDoc(doc(db, "connections", thisConnectionDataFromProfile.connectionId), {
            tasks: {
                [taskId]: {
                    title: title,
                    description: description,
                    due_date: dueDate,
                    submittal_state: taskData.submittal_state,
                    submissions: taskData.submissions
                } as TaskData
            }
        }, {merge: true});
        // Notify the Taskdoer via FCM
        const functions = getFunctions();
        const notifyTdOnTaskCreation = httpsCallable(functions, "notifyTdOnTaskCreationCallable");
        const notifyTdOnTaskCreationData = {
            taskdoerUID: profileData.connections[connectionIndex].partnerId,
            taskTitle: title,
        }
        await notifyTdOnTaskCreation(notifyTdOnTaskCreationData)
            .then((result) => {
                console.log("notifyTdOnTaskCreation result: ", result);
            })
    }

    const deleteTaskData = async () => {
        const db = getFirestore();
        const batchedWrite = writeBatch(db);
        return new Promise(resolve => setTimeout(async () => {
            batchedWrite.set(doc(db, "connections", thisConnectionDataFromProfile.connectionId), {
                tasks: {
                    [taskId]: deleteField()
                }
            }, {merge: true});
            resolve(await batchedWrite.commit());
        }, 1000))
    }

    async function deleteAndClose() {
        modal.current?.dismiss();
        setIsDeleting(true);
        try {
            await deleteTaskData()
            presentToast("Task deleted");
        } catch (e: any) {
            presentToast(`Error deleting: ${e.code}`);
            console.log("Error deleting", e);
            logClientSideError(e);
        }
        setIsDeleting(false);
    }

    async function saveAndClose() {
        modal.current?.dismiss();
        setIsSaving(true);
        try {
            await saveTaskData();
            presentToast("Task saved");
            if (resetInitValues) {
                resetInitValues();
            }
        } catch (e: any) {
            presentToast(`Error saving: ${e.code}`);
            console.log("Error saving", e)
            logClientSideError(e);
        }
        setIsSaving(false);
    }


    async function updateSubmittalState(newSubmittalState: TaskSubmittalState) {
        modal.current?.dismiss();
        setIsSaving(true);
        let db = getFirestore();
        const batch = writeBatch(db);

        if (newSubmittalState === "APPROVED" || newSubmittalState === "REJECTED") {
            const submittal_datetime = new Date().getTime()
            batch.set(doc(db, "connections", thisConnectionDataFromProfile.connectionId), {
                tasks: {
                    [taskId]: deleteField()
                }
            }, {merge: true});
            batch.set(doc(db, "completed_tasks", thisConnectionDataFromProfile.connectionId), {
                completed_tasks: arrayUnion({
                    taskId: taskId,
                    submittal_state: newSubmittalState,
                    title: title,
                    description: description,
                    due_date: dueDate,
                    submittal_datetime: submittal_datetime,
                    submissions: taskData.submissions
                } as CompletedTaskData)
            }, {merge: true});
        }

        else if (newSubmittalState === "SUBMITTED") {
            batch.set(doc(db, "connections", thisConnectionDataFromProfile.connectionId), {
                tasks: {
                    [taskId]: {
                        submittal_state: newSubmittalState,
                        submittal_datetime: Date.now(),
                    }
                }
            }, {merge: true});
            if (taskData.originating_rule_id) {
                batch.set(doc(db, "recurring_tasks", taskData.originating_rule_id), {
                    tasks_without_submittal: 0
                }, {merge: true})
            }

            // Send FCM message - notifyTmOnTaskSubmittal()
            const functions = getFunctions();
            const notifyTmOnTaskSubmittal = httpsCallable(functions, "notifyTmOnTaskSubmittal");
            const notifyTmOnTaskSubmittalData = {
                taskmasterUID: profileData.connections[connectionIndex].partnerId,
                taskTitle: title,
            }
            notifyTmOnTaskSubmittal(notifyTmOnTaskSubmittalData)
                .then((result) => {
                    console.log("notifyTmOnTaskSubmittal result: ", result);
                }).catch((e) => {
                console.log("Error: ", e)
            })
        }

        try {
            await batch.commit();
            presentToast(`Task ${newSubmittalState.toLowerCase()}`);
        } catch (e: any) {
            presentToast(`Error saving: ${e.code}`);
            console.log("Error saving", e)
            logClientSideError(e);
        }
        setIsSaving(false);
    }

    return (
        <IonModal ref={modal} trigger={`open-modal-${taskId}`}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => modal.current?.dismiss()}><IonIcon icon={arrowBackOutline}/>&nbsp;Back</IonButton>
                    </IonButtons>
                    <IonButtons hidden={!isTaskmaster} slot="end">
                        <IonButton strong={true} onClick={() => {
                            deleteAndClose();
                        }}>
                            <IonIcon icon={trashBinOutline}/>&nbsp;Delete
                        </IonButton>
                        <IonButton strong={true} onClick={() => {
                            saveAndClose();
                        }}>
                            <IonIcon icon={saveOutline}/>&nbsp;Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent key={taskId} className="ion-padding">
                <IonItem lines={"none"}>
                    <IonInput
                        label={"Title"}
                        labelPlacement={"stacked"}
                        readonly={isTaskdoer} type="text"
                              placeholder="Task title..."
                              value={title} onIonInput={(e) => setTitle(verifyIsString(e.target.value))}/>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonTextarea
                        label={"Details"}
                        labelPlacement={"stacked"}
                        autoGrow={true}
                        readonly={isTaskdoer}
                              placeholder="Task details..."
                              value={description} onIonInput={(e) => setDescription(verifyIsString(e.target.value))}/>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonIcon icon={calendar}/>
                    <IonLabel>&nbsp;Due by</IonLabel>
                    <IonDatetimeButton datetime="datetime-dueDate"/>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime readonly={isTaskdoer}
                                     value={dueDate}
                                     minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                                     onIonChange={(e) => {
                                         setDueDate(removeTimezoneFromISO(verifyIsString(e.detail.value)))
                                     }}
                                     presentation="date-time"
                                     preferWheel={true} id="datetime-dueDate"
                        />
                    </IonModal>
                </IonItem>
                <IonCard>
                    <IonButton
                        id={`open-modal-submission-123`}
                        hidden={!isTaskdoer}
                        size={"large"}
                        expand={"block"}
                    >
                        <IonIcon icon={addOutline}/> Add Submission
                    </IonButton>
                    <AddSubmissionModal connectionId={thisConnectionDataFromProfile.connectionId}
                                        taskId={taskId}/>
                </IonCard>
                {taskData.submissions?.map((submission) => {
                    return (
                        <SubmissionComponent key={submission.submissionId}
                                             submissionId={submission.submissionId}
                                             submissionSubmittalDatetime={submission.submissionSubmittalDatetime}
                                             submissionNote={submission.submissionNote}
                                             hasImage={submission.hasImage}
                                             taskId={taskId}
                                             connectionId={thisConnectionDataFromProfile.connectionId}/>
                    )
                })}
            </IonContent>
            <IonFooter>
                {
                    isTaskmaster ?
                        taskData.submittal_state === "NOT SUBMITTED" ?
                            (<IonButton
                                fill={"clear"} expand={"block"} size={"large"} disabled={true}>Task not yet
                                submitted</IonButton>)
                            :
                            (
                                <div>
                                    <IonButton size={"large"} fill={"solid"} color={"danger"}
                                               onClick={async () => {
                                                   await updateSubmittalState("REJECTED")
                                               }}>
                                        <IonIcon icon={closeOutline}/>
                                        Reject
                                    </IonButton>
                                    <IonButton size={"large"} fill={"solid"} color={"success"}
                                               style={{float: "right"}}
                                               onClick={async () => {
                                                   await updateSubmittalState("APPROVED")
                                               }}>
                                        <IonIcon icon={checkmark}/>
                                        Accept
                                    </IonButton>
                                </div>
                            )
                        :
                        taskData.submittal_state === "SUBMITTED" ?
                            (<IonButton fill={"clear"} size={"large"} expand={"block"} disabled={true}>
                                Task awaiting approval
                            </IonButton>)
                            :
                            (<IonButton onClick={async () => {
                                await updateSubmittalState("SUBMITTED")
                            }}
                                        ion-item color={"success"}
                                        size={"large"}
                                        expand={"block"}
                            >

                                <IonIcon icon={checkmark}/>
                                Submit as Done
                            </IonButton>)
                }
            </IonFooter>
        </IonModal>

    )
}

export default UncompletedTaskModal;