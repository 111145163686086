import {
    IonAccordion, IonAccordionGroup,
    IonButton,
    IonButtons, IonCheckbox, IonCol,
    IonContent, IonDatetime, IonDatetimeButton, IonGrid,
    IonHeader, IonIcon, IonInput,
    IonItem,
    IonLabel, IonList,
    IonModal, IonRow, IonSelect, IonSelectOption, IonTextarea,
    IonToolbar
} from "@ionic/react";
import {useRef, useState} from "react";
import {
    doc,
    getFirestore,
    deleteField,
    writeBatch,
} from "firebase/firestore";
import {
    arrowBackOutline,
    calendar,
    repeat,
    saveOutline,
    trashBinOutline
} from "ionicons/icons";
import {
    useConnectionDataContext,
    useConnectionIndexContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import {RuleData, TaskRecurType} from "../../shared_with_firebase_functions/types";
import {verifyIsNumber, verifyIsString} from "../../shared_with_firebase_functions/verifyInputs";
import logClientSideError from "../../utility_functions/logClientSideError";
import useToast from "../../custom_hooks/useToast";

export const RuleModal: React.FC<{
    ruleData: RuleData,
    ruleId: string,
    setIsSaving: any,
    setIsDeleting?: any,
    resetInitValues?: () => void,
    isNewRule: boolean
}> = ({
          ruleData,
          ruleId,
          resetInitValues,
          setIsSaving,
          setIsDeleting,
          isNewRule

      }) => {
    const [title, setTitle] = useState(ruleData.title);
    const [description, setDescription] = useState(ruleData.description);
    const [dueDateOffsetDay, setDueDateOffsetDay] = useState(ruleData.due_date_offset_day);
    const [dueDateOffsetTime, setDueDateOffsetTime] = useState(ruleData.due_date_offset_time);
    const [repeatAtTime, setRepeatAtTime] = useState(ruleData.repeat_at_time);
    const [repeatIntervalDays, setRepeatIntervalDays] = useState<number>(ruleData.repeat_interval_days);
    const [recurType, setRecurType] = useState<TaskRecurType>(ruleData.recur_type)
    const [recurDaysOfWeek, setRecurDaysOfWeek] = useState(ruleData.recur_days_of_week)

    const [recurDataWasUpdated, setRecurDataWasUpdated] = useState(isNewRule)

    const modal = useRef<HTMLIonModalElement>(null);

    const contentRef = useRef<HTMLIonContentElement>(null);

    function scrollToBottom() {
        setTimeout(() => {
            contentRef.current?.scrollToBottom(100);
        }, 50)
    }

    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const connectionData = useConnectionDataContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];
    const isTaskmaster = thisConnectionDataFromProfile.role === "Taskmaster";
    const isTaskdoer = thisConnectionDataFromProfile.role === "Taskdoer";

    const {presentToast} = useToast();

    async function saveRuleData() {
        const db = getFirestore();
        const batchWrite = writeBatch(db);

        const recurring_rule_ref = doc(db, "recurring_tasks", ruleId);
        batchWrite.set(recurring_rule_ref, {
                taskDataUsedToGenerateNewRecurredTask: {
                    title: title,
                    description: description,
                    submittal_state: "NOT SUBMITTED",
                },
                due_date_offset_day: dueDateOffsetDay,
                due_date_offset_time: dueDateOffsetTime,
                connectionId: thisConnectionDataFromProfile.connectionId,
                status: recurDataWasUpdated ? "needs_update" : (recurType === "DONT_REPEAT" ? "terminated" : "scheduled"),
                recurType: recurType,
                repeat_at_time: repeatAtTime,
                repeat_interval_days: repeatIntervalDays,
                recur_days_of_week: recurDaysOfWeek,
                // The only person who can save is the Taskmaster, so the taskdoer_id is always the partnerId
                taskdoer_uid: thisConnectionDataFromProfile.partnerId,
                timezone: connectionData.timezone || null
            }
            , {merge: true})

        const connectionRef = doc(db, "connections", thisConnectionDataFromProfile.connectionId)
        batchWrite.set(connectionRef, {
                rules: {
                    [ruleId]: {
                        title: title,
                        description: description,
                        due_date_offset_day: dueDateOffsetDay,
                        due_date_offset_time: dueDateOffsetTime,
                        recur_type: recurType,
                        repeat_at_time: repeatAtTime,
                        repeat_interval_days: repeatIntervalDays,
                        recur_days_of_week: recurDaysOfWeek,
                        status: recurDataWasUpdated ? "needs_update" : (recurType === "DONT_REPEAT" ? "terminated" : "scheduled"),
                        recurType: recurType,
                    } as RuleData
                }
            }
            , {merge: true})

        return batchWrite.commit();
    }

    async function saveAndClose() {
        modal.current?.dismiss();
        setIsSaving(true);
        try {
            await saveRuleData();
            presentToast("Rule saved");
            if (resetInitValues) {
                resetInitValues();
            }
        } catch (e: any) {
            presentToast(`Error saving: ${e.code}`);
            console.log("Error saving", e)
            logClientSideError(e);
        }
        setIsSaving(false);

    }


    async function deleteRuleData() {
        const db = getFirestore();
        const batchedWrite = writeBatch(db);
        // BUG FIX - setTimeout() is a workaround to this removeChild() bug I'm hitting
        // FIX THIS - https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
        // and https://gist.github.com/mrienstra/8aa4eeeeab2012d2aa8ffc7f5e45f280
        return new Promise(resolve => setTimeout(async () => {
            batchedWrite.set(doc(db, "connections", thisConnectionDataFromProfile.connectionId), {
                rules: {
                    [ruleId]: deleteField()
                }
            }, {merge: true});
            batchedWrite.delete(doc(db, "recurring_tasks", ruleId));
            resolve(await batchedWrite.commit());
        }, 1000))
    }

    async function deleteAndClose() {
        modal.current?.dismiss();
        if (setIsDeleting) {
            setIsDeleting(true);
        }
        try {
            await deleteRuleData()
            presentToast("Rule deleted");
        } catch (e: any) {
            presentToast(`Error deleting: ${e.code}`);
            console.log("Error deleting", e);
            logClientSideError(e);
        }
        if (setIsDeleting) {
            setIsDeleting(false);
        }
    }

    return (
        <IonModal ref={modal} trigger={`open-modal-${ruleId}`}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => modal.current?.dismiss()}><IonIcon icon={arrowBackOutline}/>&nbsp;Back</IonButton>
                    </IonButtons>
                    <IonButtons hidden={!isTaskmaster} slot="end">
                        <IonButton strong={true} onClick={deleteAndClose}>
                            <IonIcon icon={trashBinOutline}/>&nbsp;Delete
                        </IonButton>
                        <IonButton strong={true} onClick={saveAndClose}>
                            <IonIcon icon={saveOutline}/>&nbsp;Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent key={ruleId} ref={contentRef} className="ion-padding">
                <IonItem>
                    <IonInput
                        label={"Title"}
                        labelPlacement={"stacked"}
                        readonly={isTaskdoer} type="text"
                        placeholder="Rule title..."
                        value={title} onIonInput={(e) => setTitle(verifyIsString(e.target.value))}/>
                </IonItem>
                <IonItem>
                    <IonTextarea
                        label={"Details"}
                        labelPlacement={"stacked"}
                        autoGrow={true}
                        readonly={isTaskdoer}
                        placeholder="Rule details..."
                        value={description} onIonInput={(e) => setDescription(verifyIsString(e.target.value))}/>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonLabel>Due at</IonLabel>
                    <IonDatetimeButton datetime="datetime-dueDate"/>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime readonly={isTaskdoer}
                                     value={dueDateOffsetTime}
                                     minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                                     onIonChange={(e) => {
                                         setDueDateOffsetTime(verifyIsString(e.detail.value))
                                         console.log("IONIC DUE DATE OFFSET TIME", e.detail.value)
                                     }}
                                     presentation="time"
                                     preferWheel={true} id="datetime-dueDate"
                        />
                    </IonModal>
                </IonItem>
                <IonItem>
                    <IonInput
                        label={"After X days (0 means due same day)"}
                        labelPlacement={"stacked"}
                        readonly={isTaskdoer} type="number"
                        value={dueDateOffsetDay}
                        onIonInput={(e) => {
                            setDueDateOffsetDay(verifyIsNumber(e.target.value));
                        }}/>
                </IonItem>
                <IonItem lines={"none"}>
                    <IonIcon icon={repeat}/>
                    <IonList>
                        <IonItem lines={"none"}>
                            <IonSelect
                                onIonChange={(e) => {
                                    console.log(e.detail.value);
                                    scrollToBottom();
                                    setRecurDataWasUpdated(true);
                                    setRecurType(e.detail.value);
                                }}
                                onClick={scrollToBottom}
                                interface="popover"
                                placeholder="Repeat task?"
                                value={recurType}
                            >
                                <IonSelectOption value="DONT_REPEAT">Pause (Don't Enforce)</IonSelectOption>
                                <IonSelectOption value="DAILY">Enforce Daily</IonSelectOption>
                                <IonSelectOption value="WEEKLY">Enforce Weekly</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                </IonItem>
                <IonAccordionGroup value={recurType} disabled={recurType === "DONT_REPEAT"}>
                    <IonAccordion value="DAILY">
                        <div className="ion-padding" slot="content">
                            <IonItem lines={"none"}>
                                <IonIcon icon={calendar}/>
                                <IonLabel>&nbsp;Create Task At</IonLabel>
                                <IonDatetimeButton datetime="datetime-repeatAt"/>
                            </IonItem>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime readonly={isTaskdoer}
                                             value={repeatAtTime}
                                             minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                                             onIonChange={(e) => {
                                                 setRecurDataWasUpdated(true);
                                                 setRepeatAtTime(verifyIsString(e.detail.value).split(".")[0])
                                             }}
                                             presentation="time"
                                             preferWheel={true} id="datetime-repeatAt"
                                />
                            </IonModal>
                            <IonItem lines={"none"}>
                                <IonInput
                                    label={"Every X Days"}
                                    labelPlacement={"stacked"}
                                    readonly={isTaskdoer} type="number"
                                    value={repeatIntervalDays}
                                    onIonInput={(e) => {
                                        setRecurDataWasUpdated(true);
                                        setRepeatIntervalDays(verifyIsNumber(e.target.value));
                                    }}/>
                            </IonItem>
                        </div>
                    </IonAccordion>
                    <IonAccordion value="WEEKLY">
                        <div className="ion-padding" slot="content">
                            <IonItem lines={"none"}>
                                <IonIcon icon={calendar}/>
                                <IonLabel>&nbsp;Repeat At</IonLabel>
                                <IonDatetimeButton datetime="datetime-repeatAt"/>
                            </IonItem>
                            <IonGrid>
                                <IonRow>
                                    <IonCol><IonLabel>Su</IonLabel></IonCol>
                                    <IonCol><IonLabel>M</IonLabel></IonCol>
                                    <IonCol><IonLabel>Tu</IonLabel></IonCol>
                                    <IonCol><IonLabel>W</IonLabel></IonCol>
                                    <IonCol><IonLabel>Th</IonLabel></IonCol>
                                    <IonCol><IonLabel>F</IonLabel></IonCol>
                                    <IonCol><IonLabel>Sa</IonLabel></IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, Su: e.detail.checked});
                                        }} checked={recurDaysOfWeek?.Su}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, M: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.M}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, T: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.T}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, W: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.W}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, Th: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.Th}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, F: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.F}></IonCheckbox>
                                    </IonCol>
                                    <IonCol>
                                        <IonCheckbox onIonChange={(e) => {
                                            setRecurDataWasUpdated(true);
                                            setRecurDaysOfWeek({...recurDaysOfWeek, Sa: e.detail.checked})
                                        }} checked={recurDaysOfWeek?.Sa}></IonCheckbox>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime readonly={isTaskdoer}
                                             value={repeatAtTime}
                                             minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                                             onIonChange={(e) => {
                                                 setRecurDataWasUpdated(true);
                                                 setRepeatAtTime(verifyIsString(e.detail.value).split(".")[0]);
                                             }}
                                             presentation="time"
                                             preferWheel={true} id="datetime-repeatAt"
                                />
                            </IonModal>
                        </div>
                    </IonAccordion>
                </IonAccordionGroup>

            </IonContent>
        </IonModal>

    )
}

export default RuleModal;