import {
    IonAccordion, IonAccordionGroup,
    IonCardContent,
    IonCardHeader,
    IonContent,
    IonHeader, IonItem, IonLabel,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";

const FAQPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>FAQ</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonCardContent>
                    <IonCardHeader>
                        <IonAccordionGroup>
                            <IonAccordion value="first">
                                <IonItem slot="header">
                                    <IonLabel><b>What is Obey247?</b></IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    <p>
                                        "Do as I say, not as I do." Put down the phone, go to the gym, clean the dishes,
                                        sleep early. We know what to do, we tell others what to do,
                                        but consistently doing it ourselves is tough. We rationalize why today is an exception
                                        and ignore our own good advice. Because of this,
                                        sometimes giving up freedom actually is freedom.
                                        Sometimes letting another person be our guide and holding us accountable allows
                                        us to be our best self. Obey247 is built on this idea.<br/>
                                    </p>
                                </div>
                            </IonAccordion>
                            <IonAccordion value="what">
                                <IonItem slot="header">
                                    <IonLabel><b>What do I do?</b></IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    <p>
                                        Be a Taskmaster or a Taskdoer. The Taskmaster creates rules and assigns tasks
                                        for the Taskdoer to complete.
                                    </p>
                                    <br/>
                                    <p>
                                        For example, create a rule to wake up at 6am. Every morning at 6am, there will be a task
                                        for the Taskdoer to wake up and take a photo as proof.
                                    </p>
                                </div>
                            </IonAccordion>
                            <IonAccordion value="second">
                                <IonItem slot="header">
                                    <IonLabel><b>Can I contribute?</b></IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    We love to hear it! Subscribing or donating is extremely helpful.
                                    Any feedback is also appreciated.
                                    You can contact us at support@obeyr.com.
                                </div>
                            </IonAccordion>
                            <IonAccordion value="third">
                                <IonItem slot="header">
                                    <IonLabel><b>Tech Support</b></IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    You can contact us at support@obeyr.com.
                                </div>
                            </IonAccordion>
                            <IonAccordion value="stripe-helloh">
                                <IonItem slot="header">
                                    <IonLabel><b>Billing</b></IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    Billing will show up as HelloH App on your credit card statement.
                                    For any other billing questions, please contact us at support@obeyr.com.
                                </div>
                            </IonAccordion>
                        </IonAccordionGroup>
                    </IonCardHeader>
                </IonCardContent>
            </IonContent>
        </IonPage>
    );
};

export default FAQPage;