import {IonAlert, IonButton, IonCardTitle, IonIcon} from "@ionic/react";
import {trashBin} from "ionicons/icons";
import {useState} from "react";

const ConnectionDetailsComponentTitle: React.FC<{
    titleText: string, connectionId: string, deleteConnectionHandler: () => void, partnerName: string
}> = ({titleText, connectionId, deleteConnectionHandler, partnerName}) => {

    partnerName = partnerName || "(unconnected)"
    const [isAlertOpen, setIsAlertOpen] = useState(false)

    return (
        <IonCardTitle>
            <div style={{display: "inline-flex", justifyContent: "left", width: "90%"}}>
                {titleText}
            </div>
            <div style={{display: "inline-flex", justifyContent: "right"}}>
                <IonButton id={`present-alert-${connectionId}`}>
                    <IonIcon
                        icon={trashBin}>
                    </IonIcon>
                </IonButton>
            </div>
            <IonAlert
                isOpen={isAlertOpen}
                trigger={`present-alert-${connectionId}`}
                header="Delete connection?"
                message={`Delete connection with ${partnerName}.`}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {}
                    },
                    {
                        text: 'Delete',
                        role: 'confirm',
                        handler: () => {
                            deleteConnectionHandler()
                            return true
                        }
                    }
                ]}
            ></IonAlert>
        </IonCardTitle>
    )
}

export default ConnectionDetailsComponentTitle;