import {IonCol, IonFooter, IonGrid, IonIcon, IonItem, IonRow, IonTextarea} from "@ionic/react";
import {send} from "ionicons/icons";
import {useState} from "react";
import {addDoc, collection, getFirestore, Timestamp} from "firebase/firestore";
import {
    useConnectionIndexContext,
    useUserContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import {ChatMessage} from "../../shared_with_firebase_functions/types";

const TypingArea: React.FC<{ scrollToBottom: any }> = ({scrollToBottom}) => {
    const [textTyped, setTextTyped] = useState<string | undefined | null>("")

    const user = useUserContext()
    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const connectionId = profileData.connections[connectionIndex].connectionId;


    const sendMessage = async () => {
        if (textTyped && textTyped !== "") {
            const db = getFirestore();
            const collectionRef = collection(db, "connections", connectionId, "chat_messages")
            const chatMessage: ChatMessage = {
                senderUid: user.uid,
                timeSent: Timestamp.now(),
                text: textTyped
            }
            setTextTyped("")
            await addDoc(collectionRef, chatMessage)
            scrollToBottom();

        }
    }


    return (
        <IonFooter>
            <IonGrid>
                <IonRow style={{alignItems: "center", justifyContent: "center"}}>
                    <IonCol size={"10"}>
                        <IonItem style={{borderRadius: "12px"}} color={"secondary"} lines={"none"}>
                            <IonTextarea onIonInput={(e) => setTextTyped(e.detail.value)} autoGrow={true}
                                         value={textTyped}></IonTextarea>
                        </IonItem>
                    </IonCol>
                    <IonCol size={"2"}>
                        <IonIcon color={"primary"}
                                 onClick={sendMessage}
                                 size={"large"}
                                 icon={send}
                                 onTouchEnd={(e) => {
                                     e.preventDefault();
                                     sendMessage();
                                 }}
                        ></IonIcon>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonFooter>
    )
}

export default TypingArea;