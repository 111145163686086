import React, {ErrorInfo} from "react";
import logClientSideError from "../utility_functions/logClientSideError";
import {IonContent, IonItem, IonPage} from "@ionic/react";

export class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        logClientSideError(error);
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <IonPage>
                    <IonContent>
                        <div style={{
                                position: "absolute",
                                top: "50%",
                                left: "0%",
                                // transform: "translate(50%,50%)"
                            }}>
                            <IonItem lines={"none"}>
                                <div className={"ion-text-wrap"}>
                                    Oh no... Something went wrong. :(
                                    Please contact "support@obeyr.com" if the problem persists.
                                </div>
                            </IonItem>
                        </div>
                    </IonContent>
                </IonPage>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;