import {UserProfileData} from "../shared_with_firebase_functions/types";
import {doc, getFirestore, writeBatch, getDoc} from "firebase/firestore";
import {DateTime} from "luxon";
import {useUserContext, useUserProfileContext} from "../PrePageLoading/AuthedDataLoader";
import {useEffect} from "react";

const updateTimezone = async (profileData: UserProfileData, uid: string) => {
    const db = getFirestore()
    const batch = writeBatch(db)
    const profileDocRef = doc(db, "users", uid)
    const timezone = DateTime.now().zone.name
    batch.set(profileDocRef, {timezone: DateTime.now().zone.name}, {merge: true})
    for (const connection of profileData.connections) {
        if (connection.role === "Taskdoer" || connection.partnerId === "") {
            const connectionRef = doc(db, "connections", connection.connectionId)
            const connectionData = await getDoc(connectionRef)
            const rules = connectionData.data()?.rules
            for (const ruleId in rules) {
                const recurringTaskRef = doc(db, "recurring_tasks", ruleId)
                batch.set(recurringTaskRef, {timezone: timezone})
            }
            batch.set(connectionRef, {timezone: timezone}, {merge: true})
        }
    }
    return batch.commit()
}

export const useTimezoneUpdater = () => {
    const profileData = useUserProfileContext();
    const userData = useUserContext()
    useEffect(() => {
        const currentZone = DateTime.now().zone.name
        const savedZone = profileData?.timezone
        console.log("CURRENT ZONE", currentZone)
        console.log("SAVED ZONE", savedZone)
        if (currentZone !== savedZone) {
            updateTimezone(profileData, userData.uid)
        }
    }, [userData.uid])
}

export default useTimezoneUpdater;