import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    helpCircleOutline,
    peopleCircleOutline,
    personCircleOutline,
    walletOutline
} from "ionicons/icons";


export const NavMenuComponent: React.FC = () => {
    return (
        <>
            <IonMenu side={"end"} type={"overlay"} menuId={"navMenu"} contentId={"ion-router-outlet"}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Obey247</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonMenuToggle>
                            <IonItem routerLink="/account">
                                <IonIcon icon={personCircleOutline}/>&nbsp;Account
                            </IonItem>
                            <IonItem routerLink="/connections">
                                <IonIcon icon={peopleCircleOutline}/>&nbsp;Connections
                            </IonItem>
                            <IonItem routerLink="/subscription">
                                <IonIcon icon={walletOutline}/>&nbsp;Subscription
                            </IonItem>
                            <IonItem routerLink="/faq">
                                <IonIcon icon={helpCircleOutline}/>&nbsp;FAQ
                            </IonItem>
                        </IonMenuToggle>
                    </IonList>
                </IonContent>
            </IonMenu>
        </>
    )
}

export default NavMenuComponent;