import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonItem
} from '@ionic/react';
import NavMenuButtonComponent from "../../global_components/NavMenuButtonComponent";
import React from 'react';
import {
    useConnectionIndexContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import NewRuleButton from "./NewRuleButton";
import {RulesData} from "../../shared_with_firebase_functions/types";
import RuleComponent from "./RuleComponent";
import {DateTime} from "luxon";

const sortRulesByCreateTime = (rulesData: RulesData) => {
    rulesData = structuredClone(rulesData)
    const entries = Object.entries(rulesData)
    const sorted = entries.sort((a, b) => {
        if (a[1].repeat_at_time === null && b[1].repeat_at_time === null) return 0
        if (a[1].repeat_at_time === null) return -1
        if (b[1].repeat_at_time === null) return 1
        return DateTime.fromISO(a[1].repeat_at_time).toUnixInteger() - DateTime.fromISO(b[1].repeat_at_time).toUnixInteger()
    })
    return sorted.map((arr) => arr[0])
}

const RulesPage: React.FC<{ rulesData: RulesData, isPremiumConnection: boolean }> = ({
                                                                                         rulesData,
                                                                                         isPremiumConnection
                                                                                     }) => {
    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];

    const isTaskmaster = thisConnectionDataFromProfile.role === "Taskmaster";

    console.log("Loading RulesPage. connectionIndex: ", connectionIndex)


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Rules / {thisConnectionDataFromProfile.partnerName || "unconnected"}</IonTitle>
                    <NavMenuButtonComponent/>
                </IonToolbar>

            </IonHeader>
            <IonContent fullscreen>
                {Object.keys(rulesData).length > 0 ?
                    (sortRulesByCreateTime(rulesData).map((ruleId) => {
                        return (
                            <RuleComponent key={ruleId} ruleData={rulesData[ruleId]} ruleId={ruleId}/>
                        )
                    })) :
                    (
                        <IonItem style={{"marginTop":"20px"}} lines={"none"}>
                            <IonTitle>
                                <div className="ion-text-wrap">
                                    {isTaskmaster ? "No rules created yet." : "The Taskmaster hasn't created any rules for you yet."}
                                </div>
                            </IonTitle>
                        </IonItem>
                    )
                }

                {thisConnectionDataFromProfile.role === "Taskmaster" ?
                    <NewRuleButton
                        rulesLength={Object.keys(rulesData).length}
                        isPremiumConnection={isPremiumConnection}
                    />
                    : null
                }

            </IonContent>
        </IonPage>
    );
};

export default RulesPage;
