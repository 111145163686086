import {TaskData} from "../../shared_with_firebase_functions/types";
import React, {useState} from "react";
import {IonCard, IonCardContent, IonCardSubtitle, IonCardTitle, IonSpinner} from "@ionic/react";
import UncompletedTaskModal from "./UncompletedTaskModal";
import {DateTime} from "luxon";

const dueByText = (isoDueDate: string | null) => {
    if (!isoDueDate) {
        return "No Due Date"
    }
    let dueDate = DateTime.fromISO(isoDueDate, {zone: "Asia/Singapore"})
    const daysUntil = dueDate.toRelativeCalendar()
    const dueDateTime = dueDate.toLocaleString(DateTime.TIME_24_SIMPLE)
    if (daysUntil?.split(" ")[2] === "ago") {
        return `Due ${daysUntil}`
    } else if (daysUntil === "yesterday") {
        return "Due Yesterday"
    } else if (daysUntil === "today") {
        return `Due Today at ${dueDateTime}`;
    } else if (daysUntil === "tomorrow") {
        return `Due Tomorrow at ${dueDateTime}`;
    } else if (daysUntil?.split(" ")[2] === "days") {
        return `Due ${daysUntil} at ${dueDateTime}`
    }
}

const submittalStateText = (isoDueDate: string | null, submittalState: string) => {
    if (submittalState === "SUBMITTED") {
        return "Awaiting Approval"
    } else if (submittalState === "NOT SUBMITTED") {
        return "Not Submitted"
    }
}

const UncompletedTaskComponent: React.FC<{ taskData: TaskData, taskId: string }> = ({taskData, taskId}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <div key={taskId}>
            <IonCard disabled={isSaving || isDeleting} id={`open-modal-${taskId}`} button={true}>
                <IonCardContent>
                    <IonCardTitle>
                        {taskData.title}
                    </IonCardTitle>
                    {taskData.description ?
                        <IonCardContent>
                            {taskData.description}
                        </IonCardContent> : null}
                    <IonCardSubtitle>
                        {`${dueByText(taskData.due_date)}`}
                    </IonCardSubtitle>
                    <IonCardSubtitle>
                        {submittalStateText(taskData.due_date, taskData.submittal_state)}
                    </IonCardSubtitle>
                    {isSaving ?
                        <IonCardSubtitle>
                            <IonSpinner/>
                            Saving...
                        </IonCardSubtitle> : null
                    }
                    {isDeleting ?
                        <IonCardSubtitle>
                            <IonSpinner/>
                            Deleting...
                        </IonCardSubtitle> : null
                    }
                </IonCardContent>
            </IonCard>
            <UncompletedTaskModal taskData={taskData}
                                  taskId={taskId}
                                  setIsSaving={setIsSaving}
                                  setIsDeleting={setIsDeleting}
            />
        </div>
    )

}

export default UncompletedTaskComponent;