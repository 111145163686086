import {
    IonButton,
    IonButtons,
    IonContent, IonDatetime, IonDatetimeButton,
    IonHeader, IonIcon, IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonToolbar
} from "@ionic/react";
import {createRef, useRef} from "react";
import {
    arrowBackOutline,
    calendar,
} from "ionicons/icons";
import {
    useConnectionIndexContext,
    useUserProfileContext
} from "../../PrePageLoading/AuthedDataLoader";
import {CompletedTaskData} from "../../shared_with_firebase_functions/types";
import SubmissionComponent from "./SubmissionComponent";

export const CompletedTaskModal: React.FC<{
    taskData: CompletedTaskData,
    taskId: string,
}> = ({
          taskData,
          taskId,
      }) => {

    const modal = useRef<HTMLIonModalElement>(null);
    const modalInputTitle = useRef<HTMLIonInputElement>(null);
    const modalInputDescription = useRef<HTMLIonInputElement>(null);

    const contentRef = createRef<HTMLIonContentElement>();

    const profileData = useUserProfileContext();
    const connectionIndex = useConnectionIndexContext()
    const thisConnectionDataFromProfile = profileData.connections[connectionIndex];

    return (
        <IonModal ref={modal} trigger={`open-modal-${taskId}`}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={() => modal.current?.dismiss()}><IonIcon icon={arrowBackOutline}/>&nbsp;Back</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent key={taskId} ref={contentRef} className="ion-padding">
                <IonItem lines={"none"}>
                    <IonLabel position="stacked">Title</IonLabel>
                    <IonInput readonly={true} ref={modalInputTitle} type="text"
                              value={taskData.title} />
                </IonItem>
                <IonItem lines={"none"}>
                    <IonLabel position="stacked">Summary</IonLabel>
                    <IonInput readonly={true} ref={modalInputDescription} type="text"
                              value={taskData.description} />
                </IonItem>
                <IonItem lines={"none"}>
                    <IonIcon icon={calendar}/>
                    <IonLabel>&nbsp;Due at</IonLabel>
                    <IonDatetimeButton datetime="datetime-dueDate"/>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime readonly={true}
                                     disabled={true}
                                     value={taskData.due_date}
                                     minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                                     presentation="date-time"
                                     preferWheel={true} id="datetime-dueDate"
                        />
                    </IonModal>
                </IonItem>
                 {taskData.submissions?.map((submission) => {
                        return (
                            <SubmissionComponent key={submission.submissionId}
                                                 submissionId={submission.submissionId}
                                                 submissionSubmittalDatetime={submission.submissionSubmittalDatetime}
                                                 submissionNote={submission.submissionNote}
                                                 hasImage={submission.hasImage}
                                                 taskId={taskId}
                                                 connectionId={thisConnectionDataFromProfile.connectionId}/>
                        )
                    })}
            </IonContent>
        </IonModal>

    )
}

export default CompletedTaskModal;