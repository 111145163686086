import {getFirestore, Timestamp, doc, setDoc, arrayUnion} from "firebase/firestore";
import {getAuth} from "firebase/auth";

const logClientSideError = (error: any, errorDetails?: any) => {
    console.error("ERROR: ", error)
    const dateOfNow = new Date()
    const [UtcMonth, UtcDay, UtcYear, UtcHour] = [dateOfNow.getUTCMonth(), dateOfNow.getUTCDate(), dateOfNow.getUTCFullYear(), dateOfNow.getUTCHours()];
    const docName = new Date(UtcYear, UtcMonth, UtcDay).toString();
    const db = getFirestore();
    const auth = getAuth()
    // @ts-ignore
    const logRef = doc(db, "logs", docName)
    const attemptLogging = async () => {
        try {
            await setDoc(logRef, {
                [UtcHour]: arrayUnion({
                    time: Timestamp.now(),
                    error_message: `${error}`,
                    error_details: `${errorDetails}`,
                    url: window.document.URL,
                    userId: auth.currentUser?.uid ?? null
                })
        }, {merge: true})
        } catch (e) {
            console.error("Error logging error: ", e)
        }
    }
    attemptLogging();
}

export default logClientSideError;