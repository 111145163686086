import {IonCard, IonCol, IonGrid, IonImg, IonItem, IonRow, IonSpinner} from "@ionic/react";
import {useEffect, useState} from "react";
import useToast from "../../custom_hooks/useToast";
import useSubmissionPhotos from "../../custom_hooks/useSubmissionPhotos";
import {SubmissionData} from "../../shared_with_firebase_functions/types";
import SubmissionModal from "./SubmissionModal";

interface ISubmissionComponent extends SubmissionData {
    connectionId: string,
    taskId: string,
}

const SubmissionComponent: React.FC<ISubmissionComponent> = ({
                                                                 submissionId,
                                                                 submissionNote,
                                                                 submissionSubmittalDatetime,
                                                                 hasImage,
                                                                 connectionId,
                                                                 taskId
                                                             }) => {
    console.log("RENDERING SUBMISSION COMPONENT : ", submissionId)
    const {presentToast} = useToast();
    const {
        takePhoto,
        isImageLoading,
        loadPhoto
    } = useSubmissionPhotos(presentToast, connectionId, taskId, submissionId);
    const [photo, setPhoto] = useState<string | undefined>(undefined)

    useEffect(() => {
        loadPhoto().then((loadedPhoto) => {
            console.log("LOADED PHOTO : ", loadedPhoto)
            setPhoto(loadedPhoto);
        });
    }, [])

    return (
        <div>
            <IonCard>
                <IonGrid id={`open-modal-submission-${submissionId}`}>
                    <IonRow>
                        <IonCol size={"3"}>
                            {hasImage ?
                                (isImageLoading ?
                                        <IonSpinner/>
                                        :
                                        <IonImg src={photo}/>
                                )
                                :
                                <IonItem lines={"none"}>No Photo</IonItem>
                            }
                        </IonCol>
                        <IonCol size={"6"}>
                            {new Date(submissionSubmittalDatetime).toLocaleString()}

                        </IonCol>
                        <IonCol size={"3"}>
                            {submissionNote ? "Yes" : "No"}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <SubmissionModal submissionId={submissionId}
                             submissionSubmittalDatetime={submissionSubmittalDatetime}
                             submissionNote={submissionNote}
                             hasImage={hasImage}
                             photo={photo}
                             isImageLoading={isImageLoading}
            />
        </div>

    )
}

export default SubmissionComponent;